import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Select from 'react-select';
import SwitchField from '../../components/common/switchField';
import TimePickerSection from '../../components/common/TimePickerRange';
import { useNavigate, useParams } from 'react-router-dom';
import { MuiColorInput } from 'mui-color-input';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import {
    addProfile,
    editProfile,
    getCategoryList,
    getCompanyList,
    getCountryList,
    getProfileDetailBySlug,
    getStateList,
} from '../../utils/apiConstants';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import {
    editorConfig,
    getFormattedDateTime,
    getTabHoursValue,
    transformProfileFormData,
} from '../../utils/constants';
import Loader from '../../components/common/loader';
import UploadModal from '../../components/common/UploadModal';
import { CircularProgress } from '@mui/material';
import { useDebounce } from '../../utils/useDebounce';

const statusOption = [
    { value: 'draft', label: 'Draft' },
    { value: 'auto-draft', label: 'Auto-Draft' },
    { value: 'pending', label: 'Pending' },
    { value: 'publish', label: 'Publish' },
    { value: 'trash', label: 'Trash' },
    { value: 'private', label: 'Private' },
];

const AddEditProfiles = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [categoryOption, setCategoryOption] = useState([]);
    const [companyOption, setCompanyOption] = useState([]);
    const [setColorPickerOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [categorySearch, setCategorySearch] = useState('');
    const [categoryLoader, setCategoryLoader] = useState(true);
    const [companyLoader, setCompanyLoader] = useState(true);
    const [featuredImageURL, setFeaturedImageURL] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);
    const [backgroundImageURL, setBackgroundImageURL] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [profileDetail, setProfileDetail] = useState({});
    const [stateOption, setStateOption] = useState([]);
    const [StateLoader, setStateLoader] = useState(true);
    const [countryOption, setCountryOption] = useState([]);
    const [countryLoader, setCountryLoader] = useState(true);
    const [currentRow, setCurrentRow] = useState(null);
    const [currentImageType, setCurrentImageType] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const userDetail = localStorage.getItem('user');
    const parsedUser = userDetail ? JSON.parse(userDetail) : null;
    const [user, setUser] = useState(parsedUser);

    // Handler for input change
    const handleInputChange = newValue => {
        setCategorySearch(newValue);
    };

    const handleOpenModal = (row, imageType) => {
        setCurrentRow(row);
        setCurrentImageType(imageType);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleFileDrop = (files, row, imageType) => {
        handleFileChange(files?.[0], imageType);
        setModalOpen(false);
    };

    const handleSwitchChange = (fieldName, value, setFieldValue) => {
        setFieldValue(fieldName, value);
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Title is required')
            .matches(
                /^(?![-\s]*$).+/,
                'Title cannot be empty or only contain spaces and hyphens'
            ),
        post_status: Yup.string().required('Status is required'),
        category_name: Yup.array()
            .min(1, 'Select at least one category')
            .nullable(),
        meta_title: Yup.string()
            .required('Meta Title is required')
            .matches(
                /^(?![-\s]*$).+/,
                'Meta Title cannot be empty or only contain spaces and hyphens'
            ),
        add_tag_line: Yup.string()
            .required('Tag Line is required')
            .matches(
                /^(?![-\s]*$).+/,
                'Tag Line cannot be empty or only contain spaces and hyphens'
            ),
        add_phone: Yup.string().matches(
            /^(\+1|1)?(\s|\-)?\(?\d{3}\)?(\s|\-)?\d{3}(\s|\-)?\d{4}$/,
            'Enter a valid phone number'
        ),
        add_contact_email: Yup.string().email('Enter a valid email'),
        addr_latitude: Yup.number()
            .min(-90, 'Latitude must be between -90 and 90')
            .max(90, 'Latitude must be between -90 and 90'),
        addr_longitude: Yup.number()
            .min(-180, 'Longitude must be between -180 and 180')
            .max(180, 'Longitude must be between -180 and 180'),
        add_submitter_email: Yup.string().email('Enter a valid email'),
        add_submitter_phone: Yup.string().matches(
            /^(\+1|1)?(\s|\-)?\(?\d{3}\)?(\s|\-)?\d{3}(\s|\-)?\d{4}$/,
            'Enter a valid phone number'
        ),
        add_website: Yup.string().url('Enter a valid URL'),
        shop_url: Yup.string().url('Enter a valid URL'),
    });

    const handleFileChange = (e, imageType) => {
        const file = e;
        if (file) {
            if (imageType === 'featured_image') {
                setFeaturedImage(file || null);
                setFeaturedImageURL(URL.createObjectURL(file) || '');
            } else {
                setBackgroundImage(file || null);
                setBackgroundImageURL(URL.createObjectURL(file) || '');
            }
        }
    };

    const getCategoryOption = async () => {
        setCategoryLoader(true);
        try {
            const response = await getCategoryList(categorySearch);
            if (response?.status == 200) {
                const options = response?.data?.results?.data?.map(item => ({
                    value: item.id,
                    label: item.name,
                }));
                setCategoryOption(options);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message);
        }
        setCategoryLoader(false);
    };
    const debouncedFetchCategoryOptions = useDebounce(categorySearch, 300);
    const getStateOption = async () => {
        setStateLoader(true);
        try {
            const response = await getStateList();
            if (response?.status == 200) {
                const options = response?.data?.results?.data.map(item => ({
                    value: item.name,
                    label: item.name,
                }));
                setStateOption(options);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setStateLoader(false);
    };

    const getCountryOption = async () => {
        setCountryLoader(true);
        try {
            const response = await getCountryList();
            if (response?.status == 200) {
                const options = response?.data?.results?.data.map(item => ({
                    value: item.name,
                    label: item.name,
                }));
                setCountryOption(options);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setCountryLoader(false);
    };
    const getCompanyOption = async () => {
        setCompanyLoader(true);
        try {
            const response = await getCompanyList();
            if (response?.status == 200) {
                const options = response?.data?.results?.data.map(item => ({
                    value: item.id,
                    label: item.name,
                }));
                setCompanyOption(options);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setCompanyLoader(false);
    };

    const saveProfileDetail = async values => {
        setLoader(true);
        try {
            let transformedData = transformProfileFormData(values);
            const data = new FormData();

            data.append('title', transformedData?.title);
            data.append(
                'add_content',
                JSON.stringify(transformedData?.add_content)
            );
            data.append('category_name', transformedData?.category_name);
            data.append('slug', transformedData?.slug);
            data.append('info', JSON.stringify(transformedData?.info));
            data.append('address', JSON.stringify(transformedData?.address));
            data.append(
                'tab_hours',
                JSON.stringify(transformedData?.tab_hours)
            );
            data.append('content', transformedData?.content);
            data.append('meta_title', transformedData?.meta_title);
            data.append('meta_description', transformedData?.meta_description);
            data.append('meta_keywords', transformedData?.meta_keywords);
            if (featuredImage || backgroundImage) {
                data.append(
                    'featured_image',
                    featuredImageURL === '' ? null : featuredImage
                );
                data.append(
                    'background_header',
                    backgroundImageURL === '' ? null : backgroundImage
                );
            }
            if (featuredImageURL === '') {
                data.append('featured_image', null);
            }
            if (backgroundImageURL === '') {
                data.append('background_header', null);
            }
            let responses;
            if (slug) {
                responses = await editProfile(slug, data);
            } else {
                responses = await addProfile(data);
            }
            if (
                responses?.data?.status == 201 ||
                responses?.data?.status == 200
            ) {
                toast.success(responses?.data?.message);
            }
            navigate('/all-profiles');
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setLoader(false);
    };

    const getProfileDetailsBySlug = async slug => {
        setIsDetailLoading(true);
        try {
            const response = await getProfileDetailBySlug(slug);
            if (response?.status === 200) {
                setProfileDetail(response?.data?.data);
                setBackgroundImageURL(response?.data?.data?.background_header);
                setFeaturedImageURL(response?.data?.data?.featured_image);
                setCategorySearch(
                    response?.data?.data?.category_name?.[0]?.name
                );
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setIsDetailLoading(false);
    };
    useEffect(() => {
        if (slug) {
            getProfileDetailsBySlug(slug);
        }
        if (user?.role !== 'user') {
            getCategoryOption();
            getCompanyOption();
            getStateOption();
            getCountryOption();
        }
    }, [slug]);

    useEffect(() => {
        getCategoryOption();
    }, [debouncedFetchCategoryOptions]);

    return (
        <>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        {slug ? 'Edit' : 'Add'} Profile
                    </Typography>
                </Box>
            </Box>
            <Box className="content-layout mt-22 form-field-wrapper">
                {slug && isDetailLoading ? (
                    <Box className="h-70vh">
                        <Loader />
                    </Box>
                ) : (
                    <Formik
                        initialValues={{
                            title:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.title || ''
                                    : '',
                            slug:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.slug || ''
                                    : '',
                            content:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.content || ''
                                    : '',
                            category_name:
                                Object.keys(profileDetail).length > 0 &&
                                profileDetail.category_name?.[0]?.id
                                    ? [profileDetail.category_name?.[0]?.id]
                                    : [],
                            meta_title:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.meta_title || ''
                                    : '',
                            meta_description:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.meta_description || ''
                                    : '',
                            meta_keywords:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.meta_keywords || ''
                                    : '',
                            add_tag_line:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_tag_line || ''
                                    : '',
                            add_website:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content?.add_website ||
                                      ''
                                    : '',
                            add_phone:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content?.add_phone ||
                                      ''
                                    : '',
                            add_contact_email:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_contact_email || ''
                                    : '',
                            post_status:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.info?.post_status || ''
                                    : '',
                            add_owner:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content?.add_owner ||
                                      ''
                                    : '',
                            add_company:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content?.add_company
                                          ?.id || ''
                                    : '',
                            add_keywords:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.add_keywords || ''
                                    : '',
                            add_submitter_email:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_submitter_email || ''
                                    : '',
                            add_submitter_phone:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_submitter_phone || ''
                                    : '',
                            add_keyword_1:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_keyword_1 || ''
                                    : '',
                            add_keyword_2:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_keyword_2 || ''
                                    : '',
                            add_keyword_3:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_keyword_3 || ''
                                    : '',
                            add_overview:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.add_content
                                          ?.add_overview || ''
                                    : '',
                            addr_full_address:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address
                                          ?.addr_full_address || ''
                                    : '',
                            addr_address_1:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_address_1 ||
                                      ''
                                    : '',
                            addr_address_2:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_address_2 ||
                                      ''
                                    : '',
                            addr_address_3:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_address_3 ||
                                      ''
                                    : '',
                            addr_city:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_city || ''
                                    : '',
                            addr_state:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_state || ''
                                    : '',
                            addr_zip:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_zip || ''
                                    : '',
                            addr_county:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_county || ''
                                    : '',
                            addr_latitude:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_latitude ||
                                      ''
                                    : '',
                            addr_longitude:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_longitude ||
                                      ''
                                    : '',
                            addr_other_state:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address
                                          ?.addr_other_state || ''
                                    : '',
                            addr_country:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail?.address?.addr_country || ''
                                    : '',
                            tab_buynow_button_text:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.tab_hours
                                          ?.tab_buynow_button_text || ''
                                    : '',
                            tab_buynow_outgoing_url:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.tab_hours
                                          ?.tab_buynow_outgoing_url || ''
                                    : '',
                            tab_buynow_background_color:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.tab_hours
                                          ?.tab_buynow_background_color ||
                                      '#F36523'
                                    : '#F36523',
                            tab_buynow_text_color:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.tab_hours
                                          ?.tab_buynow_text_color || '#000000'
                                    : '#000000',
                            tab_buynow_triggered_event:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.tab_hours
                                          ?.tab_buynow_triggered_event || ''
                                    : '',
                            icon_link_url:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.icon_link_url || ''
                                    : '',
                            icon_link_image_alt:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.icon_link_image_alt ||
                                      ''
                                    : '',
                            shop_url:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.shop_url || ''
                                    : '',
                            icon_link_follow:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.icon_link_follow ||
                                      'follow'
                                    : 'follow',
                            icon_link_target:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.icon_link_target ||
                                      'self'
                                    : 'self',
                            tab_hours_monday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_monday
                                      ) || false
                                    : false,
                            tab_hours_tuesday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_tuesday
                                      ) || false
                                    : false,
                            tab_hours_wednesday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_wednesday
                                      ) || false
                                    : false,
                            tab_hours_thursday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_thursday
                                      ) || false
                                    : false,
                            tab_hours_friday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_friday
                                      ) || false
                                    : false,
                            tab_hours_saturday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_saturday
                                      ) || false
                                    : false,
                            tab_hours_sunday:
                                Object.keys(profileDetail).length > 0
                                    ? getTabHoursValue(
                                          profileDetail.tab_hours
                                              ?.tab_hours_sunday
                                      ) || false
                                    : false,
                            show_contact_form:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.show_contact_form ||
                                      true
                                    : true,
                            claim_status:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.claim_status || true
                                    : true,
                            disable_adsense:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.disable_adsense ||
                                      false
                                    : false,
                            comment_status:
                                Object.keys(profileDetail).length > 0
                                    ? profileDetail.info?.comment_status || true
                                    : true,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            saveProfileDetail(values);
                            resetForm();
                            setSubmitting(false);
                        }}
                        enableReinitialize
                    >
                        {({
                            values,
                            isSubmitting,
                            setFieldValue,
                            handleChange,
                            resetForm,
                            errors,
                        }) => (
                            <Form>
                                <>
                                    <Grid container spacing={3}>
                                        <Grid
                                            item
                                            lg={8}
                                            md={7}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Stack
                                                gap={3}
                                                className="card-height"
                                            >
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                {slug
                                                                    ? 'Edit Profile'
                                                                    : 'Profile Details'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Title
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="span"
                                                                        className="astrick"
                                                                    >
                                                                        {' '}
                                                                        *
                                                                    </Typography>
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Title"
                                                                    fullWidth
                                                                    value={
                                                                        values.title
                                                                    }
                                                                    name="title"
                                                                />
                                                                <ErrorMessage
                                                                    name="title"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Slug
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Leave Empty for Auto-Generate"
                                                                    fullWidth
                                                                    value={
                                                                        values.slug
                                                                    }
                                                                    name="slug"
                                                                    disabled
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper w-100">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Category
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="span"
                                                                        className="astrick"
                                                                    >
                                                                        {' '}
                                                                        *
                                                                    </Typography>
                                                                </Typography>
                                                                <Box className="field-count-wrapper field-wrapper">
                                                                    <Box className="field-count">
                                                                        {values
                                                                            ?.category_name?.[0] ||
                                                                            '0'}
                                                                    </Box>
                                                                    <Select
                                                                        menuPlacement="auto"
                                                                        // isMulti
                                                                        className={
                                                                            'select-ui count-dropdown'
                                                                        }
                                                                        classNamePrefix="select"
                                                                        options={
                                                                            categoryOption
                                                                        }
                                                                        isLoading={
                                                                            categoryLoader
                                                                        }
                                                                        isSearchable={
                                                                            true
                                                                        }
                                                                        placeholder="Enter Category"
                                                                        components={{
                                                                            IndicatorSeparator:
                                                                                () =>
                                                                                    null,
                                                                        }}
                                                                        onInputChange={
                                                                            handleInputChange
                                                                        }
                                                                        onChange={selectedOptions => {
                                                                            const selectedValue =
                                                                                selectedOptions
                                                                                    ? selectedOptions.value
                                                                                    : '';
                                                                            setFieldValue(
                                                                                'category_name',
                                                                                [
                                                                                    selectedValue,
                                                                                ]
                                                                            );
                                                                        }}
                                                                        value={categoryOption?.find(
                                                                            option =>
                                                                                option.value ===
                                                                                values
                                                                                    ?.category_name?.[0]
                                                                        )}
                                                                    />
                                                                </Box>
                                                                <ErrorMessage
                                                                    name="category_name"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Profile
                                                                </Typography>
                                                                <ReactQuill
                                                                    modules={
                                                                        editorConfig.modules
                                                                    }
                                                                    formats={
                                                                        editorConfig.formats
                                                                    }
                                                                    value={
                                                                        values.content
                                                                    }
                                                                    onChange={html => {
                                                                        setFieldValue(
                                                                            'content',
                                                                            html
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Profile
                                                                    Image
                                                                </Typography>
                                                                <Box className="field-browse-wrapper">
                                                                    <TextField
                                                                        className="input-ui"
                                                                        placeholder="Enter URL"
                                                                        fullWidth
                                                                        value={
                                                                            featuredImageURL ||
                                                                            ''
                                                                        }
                                                                        onChange={e => {
                                                                            setFeaturedImageURL(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        className="btn primary-btn browse-btn"
                                                                        onClick={() => {
                                                                            handleOpenModal(
                                                                                '',
                                                                                'featured_image'
                                                                            );
                                                                        }}
                                                                    >
                                                                        Browse
                                                                    </Button>
                                                                    <UploadModal
                                                                        open={
                                                                            modalOpen
                                                                        }
                                                                        row={
                                                                            currentRow
                                                                        }
                                                                        imageType={
                                                                            currentImageType
                                                                        }
                                                                        onClose={
                                                                            handleCloseModal
                                                                        }
                                                                        onDrop={
                                                                            handleFileDrop
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Background
                                                                    Header
                                                                </Typography>
                                                                <Box className="field-browse-wrapper">
                                                                    <TextField
                                                                        className="input-ui"
                                                                        placeholder="Enter URL"
                                                                        fullWidth
                                                                        value={
                                                                            backgroundImageURL ||
                                                                            ''
                                                                        }
                                                                        onChange={e => {
                                                                            setBackgroundImageURL(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        className="btn primary-btn browse-btn"
                                                                        onClick={() => {
                                                                            handleOpenModal(
                                                                                '',
                                                                                'background_header'
                                                                            );
                                                                        }}
                                                                    >
                                                                        Browse
                                                                    </Button>
                                                                    <UploadModal
                                                                        open={
                                                                            modalOpen
                                                                        }
                                                                        row={
                                                                            currentRow
                                                                        }
                                                                        imageType={
                                                                            currentImageType
                                                                        }
                                                                        onClose={
                                                                            handleCloseModal
                                                                        }
                                                                        onDrop={
                                                                            handleFileDrop
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                SEO Meta
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Meta Title
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="span"
                                                                        className="astrick"
                                                                    >
                                                                        {' '}
                                                                        *
                                                                    </Typography>
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Meta Title"
                                                                    fullWidth
                                                                    value={
                                                                        values.meta_title
                                                                    }
                                                                    name="meta_title"
                                                                />
                                                                <ErrorMessage
                                                                    name="meta_title"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Meta
                                                                    Description
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextareaAutosize
                                                                    }
                                                                    className="textarea-ui"
                                                                    placeholder="Enter Meta Description"
                                                                    fullWidth
                                                                    value={
                                                                        values.meta_description
                                                                    }
                                                                    name="meta_description"
                                                                />
                                                                <ErrorMessage
                                                                    name="meta_description"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Meta
                                                                    Keywords
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Meta Keywords"
                                                                    fullWidth
                                                                    value={
                                                                        values.meta_keywords
                                                                    }
                                                                    name="meta_keywords"
                                                                />
                                                                <ErrorMessage
                                                                    name="name"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                Additional
                                                                Content
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Company
                                                                </Typography>
                                                                <Box className="field-count-wrapper field-wrapper">
                                                                    <Box className="field-count">
                                                                        {values?.add_company ||
                                                                            '0'}
                                                                    </Box>
                                                                    <Select
                                                                        menuPlacement="auto"
                                                                        className={
                                                                            'select-ui count-dropdown'
                                                                        }
                                                                        classNamePrefix="select"
                                                                        options={
                                                                            companyOption
                                                                        }
                                                                        isLoading={
                                                                            companyLoader
                                                                        }
                                                                        isSearchable={
                                                                            true
                                                                        }
                                                                        placeholder="Enter Company"
                                                                        components={{
                                                                            IndicatorSeparator:
                                                                                () =>
                                                                                    null,
                                                                        }}
                                                                        onChange={selectedOptions => {
                                                                            const selectedValue =
                                                                                selectedOptions
                                                                                    ? selectedOptions.value
                                                                                    : '';
                                                                            setFieldValue(
                                                                                'add_company',
                                                                                selectedValue
                                                                            );
                                                                        }}
                                                                        value={companyOption.find(
                                                                            option =>
                                                                                option.value ===
                                                                                values.add_company
                                                                        )}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Tag Line
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="span"
                                                                        className="astrick"
                                                                    >
                                                                        {' '}
                                                                        *
                                                                    </Typography>
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Tag Line"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_tag_line
                                                                    }
                                                                    name="add_tag_line"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_tag_line"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Overview
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextareaAutosize
                                                                    }
                                                                    className="textarea-ui"
                                                                    placeholder="Enter Overview"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_overview
                                                                    }
                                                                    name="add_overview"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_overview"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Website
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Website"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_website
                                                                    }
                                                                    name="add_website"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_website"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Phone
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Phone"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_phone
                                                                    }
                                                                    name="add_phone"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_phone"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Profile
                                                                    Contact
                                                                    Email
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Profile Contact Email"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_contact_email
                                                                    }
                                                                    name="add_contact_email"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_contact_email"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Owner/General
                                                                    Manager's
                                                                    Name
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Owner/General Manager's Name"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_owner
                                                                    }
                                                                    name="add_owner"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_owner"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Submitter's
                                                                    Email
                                                                    Address
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Submitter's Email Address"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_submitter_email
                                                                    }
                                                                    name="add_submitter_email"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_submitter_email"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Submitter's
                                                                    Phone Number
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Submitter's Phone Number"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_submitter_phone
                                                                    }
                                                                    name="add_submitter_phone"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_submitter_phone"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Keyword 1
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Keyword 1"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_keyword_1
                                                                    }
                                                                    name="add_keyword_1"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_keyword_1"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Keyword 2
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Keyword 2"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_keyword_2
                                                                    }
                                                                    name="add_keyword_2"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_keyword_2"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Keyword 3
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Keyword 3"
                                                                    fullWidth
                                                                    value={
                                                                        values.add_keyword_3
                                                                    }
                                                                    name="add_keyword_3"
                                                                />
                                                                <ErrorMessage
                                                                    name="add_keyword_3"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                Address
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Full Address
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Full Address"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_full_address
                                                                    }
                                                                    name="addr_full_address"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_full_address"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Address 1
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Address 1"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_address_1
                                                                    }
                                                                    name="addr_address_1"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_address_1"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Address 2
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Address 2"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_address_2
                                                                    }
                                                                    name="addr_address_2"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_address_2"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Address 3
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Address 3"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_address_3
                                                                    }
                                                                    name="addr_address_3"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_address_3"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    City
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter City"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_city
                                                                    }
                                                                    name="addr_city"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_city"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    State
                                                                </Typography>
                                                                <Select
                                                                    menuPlacement="auto"
                                                                    className={
                                                                        'select-ui'
                                                                    }
                                                                    classNamePrefix="select"
                                                                    options={
                                                                        stateOption
                                                                    }
                                                                    isLoading={
                                                                        StateLoader
                                                                    }
                                                                    placeholder="Select State"
                                                                    components={{
                                                                        IndicatorSeparator:
                                                                            () =>
                                                                                null,
                                                                    }}
                                                                    onChange={selectedOptions => {
                                                                        const selectedValues =
                                                                            selectedOptions?.value;
                                                                        setFieldValue(
                                                                            'addr_state',
                                                                            selectedValues
                                                                        );
                                                                    }}
                                                                    value={stateOption?.filter(
                                                                        option =>
                                                                            values?.addr_state?.includes(
                                                                                option?.value
                                                                            )
                                                                    )}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Zip Code
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Zip Code"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_zip
                                                                    }
                                                                    name="addr_zip"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_zip"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    County
                                                                </Typography>
                                                                <Select
                                                                    menuPlacement="auto"
                                                                    className={
                                                                        'select-ui'
                                                                    }
                                                                    classNamePrefix="select"
                                                                    options={
                                                                        countryOption
                                                                    }
                                                                    isLoading={
                                                                        countryLoader
                                                                    }
                                                                    placeholder="Select Country"
                                                                    components={{
                                                                        IndicatorSeparator:
                                                                            () =>
                                                                                null,
                                                                    }}
                                                                    onChange={selectedOptions => {
                                                                        const selectedValues =
                                                                            selectedOptions?.value;
                                                                        setFieldValue(
                                                                            'addr_county',
                                                                            selectedValues
                                                                        );
                                                                    }}
                                                                    value={countryOption?.filter(
                                                                        option =>
                                                                            values?.addr_county?.includes(
                                                                                option?.value
                                                                            )
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_county"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Latitude
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Latitude"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_latitude
                                                                    }
                                                                    name="addr_latitude"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_latitude"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Longitude
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Longitude"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_longitude
                                                                    }
                                                                    name="addr_longitude"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_longitude"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Other State
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Other State"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_other_state
                                                                    }
                                                                    name="addr_other_state"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_other_state"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={6}
                                                            md={12}
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Country
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Other Country"
                                                                    fullWidth
                                                                    value={
                                                                        values.addr_country
                                                                    }
                                                                    name="addr_country"
                                                                />
                                                                <ErrorMessage
                                                                    name="addr_country"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={4}
                                            md={5}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Stack
                                                gap={3}
                                                className="card-height"
                                            >
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                Status
                                                                Information
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Status
                                                                    <Typography
                                                                        variant="body1"
                                                                        component="span"
                                                                        className="astrick"
                                                                    >
                                                                        {' '}
                                                                        *
                                                                    </Typography>
                                                                </Typography>
                                                                <Select
                                                                    menuPlacement="auto"
                                                                    className={
                                                                        'select-ui'
                                                                    }
                                                                    classNamePrefix="select"
                                                                    options={
                                                                        statusOption
                                                                    }
                                                                    placeholder="Select Status"
                                                                    components={{
                                                                        IndicatorSeparator:
                                                                            () =>
                                                                                null,
                                                                    }}
                                                                    onChange={selectedOptions => {
                                                                        const selectedValues =
                                                                            selectedOptions?.value;
                                                                        setFieldValue(
                                                                            'post_status',
                                                                            selectedValues
                                                                        );
                                                                    }}
                                                                    value={statusOption?.filter(
                                                                        option =>
                                                                            values?.post_status?.includes(
                                                                                option?.value
                                                                            )
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    name="post_status"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper switch-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Show Contact
                                                                    Form
                                                                </Typography>
                                                                <SwitchField
                                                                    checked={
                                                                        values.show_contact_form
                                                                    }
                                                                    onChange={value =>
                                                                        handleSwitchChange(
                                                                            'show_contact_form',
                                                                            value,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper switch-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Profile
                                                                    Claimed
                                                                </Typography>
                                                                <SwitchField
                                                                    checked={
                                                                        values.claim_status
                                                                    }
                                                                    onChange={value =>
                                                                        handleSwitchChange(
                                                                            'claim_status',
                                                                            value,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper switch-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Disable
                                                                    Adsense
                                                                </Typography>
                                                                <SwitchField
                                                                    checked={
                                                                        values.disable_adsense
                                                                    }
                                                                    onChange={value =>
                                                                        handleSwitchChange(
                                                                            'disable_adsense',
                                                                            value,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper switch-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Allow
                                                                    Comments/Reviews
                                                                </Typography>
                                                                <SwitchField
                                                                    checked={
                                                                        values.comment_status
                                                                    }
                                                                    onChange={value =>
                                                                        handleSwitchChange(
                                                                            'comment_status',
                                                                            value,
                                                                            setFieldValue
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper switch-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Created At
                                                                </Typography>
                                                                <Typography
                                                                    variant="body1"
                                                                    component="span"
                                                                    classsName="date-text"
                                                                >
                                                                    {slug
                                                                        ? profileDetail?.created_at
                                                                            ? getFormattedDateTime(
                                                                                  profileDetail?.created_at
                                                                              )
                                                                            : '-'
                                                                        : getFormattedDateTime(
                                                                              new Date()
                                                                          )}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper switch-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Last Updated
                                                                </Typography>
                                                                <Typography
                                                                    variant="body1"
                                                                    component="span"
                                                                    classsName="date-text"
                                                                >
                                                                    {getFormattedDateTime(
                                                                        new Date()
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                Buy Now Button
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Button Text
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    value={
                                                                        values.tab_buynow_button_text
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Button Text"
                                                                    fullWidth
                                                                    name="tab_buynow_button_text"
                                                                />
                                                                <ErrorMessage
                                                                    name="tab_buynow_button_text"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Outgoing URL
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    value={
                                                                        values.tab_buynow_outgoing_url
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Outgoing URL"
                                                                    fullWidth
                                                                    name="tab_buynow_outgoing_url"
                                                                />
                                                                <ErrorMessage
                                                                    name="tab_buynow_outgoing_url"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Shop URL
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    className="input-ui"
                                                                    value={
                                                                        values.shop_url
                                                                    }
                                                                    placeholder="Enter Shop URL"
                                                                    fullWidth
                                                                    name="shop_url"
                                                                />
                                                                <ErrorMessage
                                                                    name="shop_url"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Background
                                                                    Color
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        position:
                                                                            'relative',
                                                                    }}
                                                                >
                                                                    <MuiColorInput
                                                                        name="tab_buynow_background_color"
                                                                        value={
                                                                            values.tab_buynow_background_color
                                                                        }
                                                                        onChange={color => {
                                                                            handleChange(
                                                                                {
                                                                                    target: {
                                                                                        name: 'tab_buynow_background_color',
                                                                                        value: color,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        onClose={() =>
                                                                            setColorPickerOpen(
                                                                                false
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Text Color
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        position:
                                                                            'relative',
                                                                    }}
                                                                >
                                                                    {/* <TextField
                                                                                        className="input-ui"
                                                                                        placeholder="Enter Text Color"
                                                                                        fullWidth
                                                                                    />
                                                                                    <Box
                                                                                        className="color-picker-wrapper"
                                                                                        sx={{
                                                                                            backgroundColor:
                                                                                                '#333333',
                                                                                        }}
                                                                                    ></Box> */}
                                                                    <MuiColorInput
                                                                        name="tab_buynow_text_color"
                                                                        value={
                                                                            values.tab_buynow_text_color
                                                                        }
                                                                        onChange={color => {
                                                                            handleChange(
                                                                                {
                                                                                    target: {
                                                                                        name: 'tab_buynow_text_color',
                                                                                        value: color,
                                                                                    },
                                                                                }
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Click
                                                                    Tracking
                                                                    Identification
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    value={
                                                                        values.tab_buynow_triggered_event
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Click Tracking Identification"
                                                                    fullWidth
                                                                    name="tab_buynow_triggered_event"
                                                                />
                                                                <ErrorMessage
                                                                    name="tab_buynow_triggered_event"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                Icon Link
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Icon Link
                                                                    URL
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    value={
                                                                        values.icon_link_url
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Icon Link URL"
                                                                    fullWidth
                                                                    name="icon_link_url"
                                                                />
                                                                <ErrorMessage
                                                                    name="icon_link_url"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Image Alt
                                                                </Typography>
                                                                <Field
                                                                    as={
                                                                        TextField
                                                                    }
                                                                    value={
                                                                        values.icon_link_image_alt
                                                                    }
                                                                    className="input-ui"
                                                                    placeholder="Enter Image Alt"
                                                                    fullWidth
                                                                    name="icon_link_image_alt"
                                                                />
                                                                <ErrorMessage
                                                                    name="icon_link_image_alt"
                                                                    component="div"
                                                                    className="error-message"
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper radio-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Follow
                                                                </Typography>
                                                                <RadioGroup
                                                                    name="icon_link_follow"
                                                                    value={
                                                                        values.icon_link_follow
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    className="radio-group-wrapper"
                                                                >
                                                                    <FormControlLabel
                                                                        value="follow"
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label="follow"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="nofollow"
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label="nofollow"
                                                                    />
                                                                </RadioGroup>
                                                            </Box>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Box className="field-wrapper radio-field-wrapper">
                                                                <Typography
                                                                    variant="body1"
                                                                    component="label"
                                                                >
                                                                    Target
                                                                </Typography>
                                                                <RadioGroup
                                                                    className="radio-group-wrapper"
                                                                    name="icon_link_target"
                                                                    value={
                                                                        values.icon_link_target
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                >
                                                                    <FormControlLabel
                                                                        value="self"
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label="_self"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="blank"
                                                                        control={
                                                                            <Radio />
                                                                        }
                                                                        label="_blank"
                                                                    />
                                                                </RadioGroup>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box className="card-wrapper p-16">
                                                    <Grid container spacing={3}>
                                                        <Grid
                                                            item
                                                            lg={12}
                                                            md={12}
                                                            sm={12}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h3"
                                                                component="h3"
                                                                className="card-title"
                                                            >
                                                                Opening Hours
                                                            </Typography>
                                                        </Grid>
                                                        <TimePickerSection
                                                            day="Monday"
                                                            switchState={
                                                                values.tab_hours_monday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <TimePickerSection
                                                            day="Tuesday"
                                                            switchState={
                                                                values.tab_hours_tuesday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <TimePickerSection
                                                            day="Wednesday"
                                                            switchState={
                                                                values.tab_hours_wednesday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <TimePickerSection
                                                            day="Thursday"
                                                            switchState={
                                                                values.tab_hours_thursday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <TimePickerSection
                                                            day="Friday"
                                                            switchState={
                                                                values.tab_hours_friday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <TimePickerSection
                                                            day="Saturday"
                                                            switchState={
                                                                values.tab_hours_saturday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                        <TimePickerSection
                                                            day="Sunday"
                                                            switchState={
                                                                values.tab_hours_sunday
                                                            }
                                                            handleSwitchChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                            handleTimeChange={(
                                                                fieldName,
                                                                value
                                                            ) =>
                                                                setFieldValue(
                                                                    fieldName,
                                                                    value
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Box>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Box className="footer-wrapper">
                                        <Box className="btn-wrapper">
                                            <Button
                                                className="btn primary-btn"
                                                type="submit"
                                                disabled={isSubmitting}
                                                endIcon={
                                                    isSubmitting && (
                                                        <CircularProgress
                                                            sx={{
                                                                color: 'var(--white-color)',
                                                            }}
                                                            size={16}
                                                        />
                                                    )
                                                }
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                className="btn secondary-btn"
                                                onClick={() => {
                                                    navigate('/all-profiles');
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Box>
                                </>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </>
    );
};

export default AddEditProfiles;
