// Pagination.js

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';

const Pagination = ({ selectedPage, totalPage, setSelectedPage }) => {
    return (
        <Box className="item-page-count">
            <Button
                onClick={() => setSelectedPage(1)}
                disabled={selectedPage === 1}
            >
                <FirstPageRoundedIcon />
            </Button>
            <Button
                onClick={() => setSelectedPage(selectedPage - 1)}
                disabled={selectedPage === 1}
            >
                <ChevronLeftRoundedIcon />
            </Button>
            <TextField
                variant="outlined"
                type="text"
                size="small"
                placeholder="1"
                value={selectedPage}
                onChange={e => {
                    const value = e.target.value;
                    // Allow only numbers
                    if (/^\d*$/.test(value)) {
                        setSelectedPage(value === '' ? '' : Number(value));
                    }
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
            <Typography variant="body1" component="span">
                of {totalPage}
            </Typography>
            <Button
                onClick={() => setSelectedPage(selectedPage + 1)}
                className="active"
                disabled={selectedPage >= totalPage}
            >
                <ChevronRightRoundedIcon />
            </Button>
            <Button
                onClick={() => setSelectedPage(totalPage)}
                disabled={selectedPage >= totalPage}
            >
                <LastPageRoundedIcon />
            </Button>
        </Box>
    );
};

export default Pagination;
