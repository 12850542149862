import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import SwitchField from './switchField';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const TimePickerRange = ({
    day,
    switchState,
    handleSwitchChange,
    handleTimeChange,
}) => {
    const today = new Date();
    const defaultStartTime = dayjs(today).hour(9).minute(0).toDate();
    const defaultEndTime = dayjs(today).hour(23).minute(59).toDate();

    const [startTime, setStartTime] = useState(defaultStartTime);
    const [endTime, setEndTime] = useState(defaultEndTime);

    useEffect(() => {
        if (switchState) {
            if (typeof switchState !== 'boolean' && switchState !== 'Closed') {
                const [start, end] = switchState
                    ?.split('-')
                    .map(time => {
                        time = time.trim(); // Trim whitespace
                        let hour, minute;

                        // Check for "am/pm" format
                        if (time?.includes('am') || time?.includes('pm')) {
                            const [timeString, period] = time.split(' ');
                            const [h, m] = timeString.split(':').map(Number);
                            hour = period === 'pm' && h < 12 ? h + 12 : h;
                            hour = period === 'am' && h === 12 ? 0 : hour;
                            minute = m || 0; // Default to 0 if minutes are not specified

                            return dayjs(today).hour(hour).minute(minute).toDate();
                        }
                        else {
                            return dayjs(today)
                            .hour(time?.split(':')[0])
                            .minute(time.split(':')[1])
                            .toDate()
                        }
                    });
                setStartTime(start);
                setEndTime(end);
            } else {
                const formattedStartTime = startTime
                    ? dayjs(startTime).format('H:mm')
                    : '';
                const formattedEndTime = endTime
                    ? dayjs(endTime).format('H:mm')
                    : '';
                handleTimeChange(
                    `tab_hours_${day.toLowerCase()}`,
                    `${formattedStartTime}-${formattedEndTime}`
                );
            }
        } else {
            setStartTime(defaultStartTime);
            setEndTime(defaultEndTime);
        }
    }, [switchState]);

    const handleStartTimeChange = value => {
        setStartTime(value);
        const formattedStartTime = value ? dayjs(value).format('H:mm') : '';
        const formattedEndTime = endTime ? dayjs(endTime).format('H:mm') : '';
        handleTimeChange(
            `tab_hours_${day.toLowerCase()}`,
            `${formattedStartTime}-${formattedEndTime}`
        );
    };

    const handleEndTimeChange = value => {
        setEndTime(value);
        const formattedStartTime = startTime
            ? dayjs(startTime).format('H:mm')
            : '';
        const formattedEndTime = value ? dayjs(value).format('H:mm') : '';
        handleTimeChange(
            `tab_hours_${day.toLowerCase()}`,
            `${formattedStartTime}-${formattedEndTime}`
        );
    };

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="field-wrapper switch-field-wrapper">
                <Typography variant="body1" component="label">
                    {day}
                </Typography>
                <SwitchField
                    checked={switchState}
                    onChange={value =>
                        handleSwitchChange(
                            `tab_hours_${day.toLowerCase()}`,
                            value
                        )
                    }
                />
            </Box>
            <Box className="time-field-wrapper">
                <ThemeProvider
                    theme={createTheme({
                        palette: { primary: { main: '#1976d2' } },
                    })}
                >
                    <DatePicker
                        selected={startTime}
                        onChange={handleStartTimeChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Start Time"
                        dateFormat="h:mm aa"
                        disabled={!switchState}
                        customInput={
                            <TextField
                                // label="Start Time"
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={!switchState}
                            />
                        }
                    />
                    <Box>-</Box>
                        <DatePicker
                            selected={endTime}
                            onChange={handleEndTimeChange}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="End Time"
                            dateFormat="h:mm aa"
                            disabled={!switchState}
                            // minTime={dayjs(startTime).add(1, 'minute').toDate()} // End time must be at least 1 minute after start time
                            // maxTime={dayjs(startTime).add(24, 'hours').toDate()} // Set maxTime to limit selection
                            customInput={
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled={!switchState}
                                />
                            }
                        />
                    </ThemeProvider>
            </Box>
        </Grid>
    );
};

export default TimePickerRange;
