import React from 'react';
import {Box, Button, Typography} from '@mui/material';




const Dashboard = () => {

    return (
        <>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Dashboard
                    </Typography>
                </Box>
                <Box className="content-header-right">
                    
                </Box>
            </Box>
            <Box className="content-layout">
               <Box className="card-wrapper p-16 coming-soon">
                    <Typography variant="h5" component="h5">
                        {/* Coming Soon... */}
                    </Typography>
               </Box>
            </Box>
        </>
    );
};

export default Dashboard;
