import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" className="h-100">
      <CircularProgress className='active-menu' align="center"/>
    </Box>
  );
}

export default Loader;
