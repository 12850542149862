import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Stack,
    Grid,
    CircularProgress,
    createTheme,
    useMediaQuery,
    Modal,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    addCompany,
    deleteCompany,
    editCompany,
    getCompanies,
    getCompanyDetailById,
} from '../../utils/apiConstants';
import { handleSelectChange, pagintaionPageCount } from '../../utils/constants';
import Pagination from '../../components/common/pagination';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DeleteTrash from '../../assets/images/icons/delete.svg';
import Loader from '../../components/common/loader';
import { useDebounce } from '../../utils/useDebounce';
import {
    ArrowDownward,
    ArrowUpward,
    Height,
    SwapVert,
} from '@mui/icons-material';

const theme = createTheme();

const Companies = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [companyDetail, setCompanyDetail] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [totalCount, setTotalCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [selectedId, setSelectedId] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Company Name is required')
            .matches(
                /^(?![-\s]*$).+/,
                'Name cannot be empty or only contain spaces and hyphens'
            ),
    });

    const [open, setOpen] = useState(false);
    const trashModalOpen = () => setOpen(true);
    const trashModalClose = () => setOpen(false);

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedId([]);
        } else {
            const allId = companies?.map(item => item?.id);
            setSelectedId(allId);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleRowCheckboxChange = id => {
        if (selectedId.includes(id)) {
            setSelectedId(selectedId.filter(item => item !== id));
        } else {
            setSelectedId([...selectedId, id]);
        }
    };
    const deleteCompanyById = async () => {
        setLoader(true);
        try {
            if (selectedId) {
                
                const responses = await deleteCompany(selectedId.join(','));

                if (responses?.data?.status == 200) {
                    setSelectedPage(1)
                    toast.success(responses?.data?.message);
                    setOpen(false);
                    setCompanyDetail([])
                    setHeaderChecked(false)
                    getCompanyList()
                    navigate('/companies')
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message);
        }
        setLoader(false);
    };

    const getCompanyList = async (
        newSortOrder = sortOrder,
        column = sortBy
    ) => {
        setLoader(true);
        try {
            const response = await getCompanies(
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                searchValue,
                sortBy || column
                    ? (newSortOrder || sortOrder) === 'asc'
                        ? column || sortBy
                        : `-` + column || sortBy
                    : sortBy
            );
            if (response?.status == 200) {
                setCompanies(response?.data?.results?.data);
                setTotalCount(response?.data?.count || 0);
                setTotalPage(
                    Math.ceil(response?.data?.count / showPerPage) || 0
                );
            }else{
                toast.error(
                    response?.data?.detail ||
                        response?.data?.message
                ); 
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.detail ||
                    error?.response?.data?.message ||
                    error.message
            );
        }
        setLoader(false);
    };

    const fetchData = pageSize => {
        setSelectedPage(1)
        setShowPerPage(pageSize);
    };
    const saveCompanyDetail = async Details => {
        setLoader(true);
        try {
            const data = new FormData();
            data.append('name', Details.name);
            let response;
            if (slug) {
                response = await editCompany(slug, data);
            } else {
                response = await addCompany(data);
            }
            if (
                response?.data?.status == 201 ||
                response?.data?.status == 200
            ) {
                toast.success(response?.data?.message);
                navigate(`/companies`);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data?.detail || error.message);
        }
        setLoader(false);
    };

    const getCompanyDetailsById = async slug => {
        setIsDetailLoading(true);
        try {
            const response = await getCompanyDetailById(slug);
            if (response?.status === 200) {
                setCompanyDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.detail ||
                    error?.response?.data?.message ||
                    error.message
            );
        }
        setIsDetailLoading(false);
    };

    useEffect(() => {
        getCompanyList();
        if (slug) {
            getCompanyDetailsById(slug);
        } else {
            setIsDetailLoading(false);
        }
    }, [slug]);

    useEffect(() => {
        getCompanyList();
    }, [debouncedSearchTerm, showPerPage, selectedPage]);

    const [openRow, setOpenRow] = useState(null);

    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    const handleSort = column => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getCompanyList(newSortOrder, column);
    };

    const getSortIcon = column => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward size={20} />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward size={20} />;
        } else {
            return <SwapVert size={20} />;
        }
    };

    return (
        <>
            <Box className="content-header companies-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Companies
                    </Typography>
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search Companies"
                            onChange={e => {
                                setSearchValue(e.target.value);setSelectedPage(1)
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Grid container spacing={3}>
                    <Grid item lg={4} md={5} sm={12} xs={12}>
                        <Box className="card-wrapper p-16">
                            {isDetailLoading ? (
                                <Box style={{ height: '40vh' }}>
                                    <Loader />
                                </Box>
                            ) : (
                                <Formik
                                    initialValues={{
                                        name: companyDetail?.name
                                            ? companyDetail?.name
                                            : '',
                                        slug: companyDetail?.slug
                                            ? companyDetail?.slug
                                            : '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(
                                        values,
                                        { setSubmitting, resetForm }
                                    ) => {
                                        saveCompanyDetail(values);
                                        resetForm();
                                        setSubmitting(false);
                                    }}
                                    enableReinitialize
                                >
                                    {({ values, isSubmitting, resetForm }) => (
                                        <Form>
                                            <Grid container spacing={3}>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Company Name
                                                            <Typography
                                                                variant="body1"
                                                                component="span"
                                                                className="astrick"
                                                            >
                                                                {' '}
                                                                *
                                                            </Typography>
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            className="input-ui"
                                                            name="name"
                                                            value={values.name}
                                                            placeholder="Enter Company Name"
                                                            fullWidth
                                                        />
                                                        <ErrorMessage
                                                            name="name"
                                                            component="div"
                                                            className="error-message"
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                >
                                                    <Box className="field-wrapper">
                                                        <Typography
                                                            variant="body1"
                                                            component="label"
                                                        >
                                                            Slug
                                                        </Typography>
                                                        <Field
                                                            as={TextField}
                                                            className="input-ui"
                                                            name="slug"
                                                            value={values.slug}
                                                            placeholder="Leave Empty for Auto-Generate"
                                                            fullWidth
                                                            disabled={slug}
                                                        />
                                                    </Box>
                                                </Grid>
                                                {slug ? (
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Stack
                                                            gap={2}
                                                            direction={'row'}
                                                        >
                                                            <Button
                                                                type="submit"
                                                                className="btn primary-btn w-100"
                                                                disabled={
                                                                    isSubmitting
                                                                }
                                                            >
                                                                Update
                                                            </Button>
                                                            <Button
                                                                className="btn secondary-btn w-100"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setCompanyDetail(
                                                                        []
                                                                    );
                                                                    navigate(
                                                                        '/companies'
                                                                    );
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                ) : (
                                                    <Grid
                                                        item
                                                        lg={12}
                                                        md={12}
                                                        sm={12}
                                                        xs={12}
                                                    >
                                                        <Button
                                                            type="submit"
                                                            className="btn primary-btn w-100"
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            endIcon={
                                                                isSubmitting && (
                                                                    <CircularProgress
                                                                        sx={{
                                                                            color: 'var(--white-color)',
                                                                        }}
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </Box>
                    </Grid>
                    <Grid item lg={8} md={7} sm={12} xs={12}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="companies-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            <TableCell className="table-checkbox-width">
                                                <Checkbox
                                                    className="checkbox-ui"
                                                    checked={headerChecked}
                                                    onChange={
                                                        handleHeaderCheckboxChange
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('name');
                                                    handleSort('name');
                                                }}
                                            >
                                                Name {getSortIcon('name')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('slug');
                                                    handleSort('slug');
                                                }}
                                            >
                                                Slug {getSortIcon('slug')}
                                            </TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : companies?.length > 0 ? (
                                            companies?.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        onClick={e => {
                                                            if (
                                                                isSmallScreen &&
                                                                e.target
                                                                    .type !==
                                                                    'checkbox'
                                                            ) {
                                                                handleRowClick(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                        className={
                                                            openRow === index
                                                                ? 'open'
                                                                : ''
                                                        }
                                                    >
                                                        <TableCell className="table-checkbox-width">
                                                            <Checkbox
                                                                className="checkbox-ui"
                                                                checked={selectedId.includes(
                                                                    row?.id
                                                                )}
                                                                onChange={() =>
                                                                    handleRowCheckboxChange(
                                                                        row?.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="show-text-arrow-content">
                                                                <Link
                                                                    to={`/companies/edit/${row?.slug}`}
                                                                    className="MuiLink-root fw-600"
                                                                >
                                                                    {row?.name}
                                                                </Link>
                                                                {openRow ===
                                                                index ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {row.slug}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                gap={3}
                                                                className="action-wrapper"
                                                            >
                                                                <Button
                                                                    className="btn delete-btn"
                                                                    onClick={() => {
                                                                        setSelectedId(
                                                                            [
                                                                                ...selectedId,
                                                                                row?.id,
                                                                            ]
                                                                        );
                                                                        trashModalOpen();
                                                                    }}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    {openRow === index && (
                                                        <TableRow className="accordion-content">
                                                            <TableCell
                                                                colSpan={2}
                                                            >
                                                                <Box className="accordion-box">
                                                                    <Stack
                                                                        className=""
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="MuiLink-root fw-600"
                                                                        >
                                                                            Name:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                <Link
                                                                                    to={`/companies/edit/${row?.slug}`}
                                                                                    className="MuiLink-root fw-600"
                                                                                >
                                                                                    {
                                                                                        row.name
                                                                                    }
                                                                                </Link>
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Slug:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row.slug
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Stack
                                                                            direction={
                                                                                'row'
                                                                            }
                                                                            gap={
                                                                                3
                                                                            }
                                                                            className="action-wrapper"
                                                                        >
                                                                            <Button
                                                                                className="btn delete-btn"
                                                                                onClick={() => {
                                                                                    setSelectedId(
                                                                                        [
                                                                                            ...selectedId,
                                                                                            row?.id,
                                                                                        ]
                                                                                    );
                                                                                    trashModalOpen();
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon />
                                                                            </Button>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={4}
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={trashModalClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-ui">
                        <Box className="modal-body">
                            <Box className="confirmation-message-wrapper">
                                <Box className="image-wrapper">
                                    <img src={DeleteTrash} />
                                </Box>
                                <Box className="confirm-content-wrapper">
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        className="confirm-title"
                                    >
                                        Are you sure want to delete this company?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        className="confirm-para"
                                    >
                                        This action is permanent and cannot be
                                        undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="modal-footer-wrapper">
                                <Button
                                    className="btn red-btn"
                                    onClick={() => deleteCompanyById()}
                                >
                                    Delete
                                </Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={trashModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Companies;
