import React, { useEffect, useState } from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import dashboardIcon from '../../assets/images/icons/dasboard.svg';
import profileIcon from '../../assets/images/icons/profile.svg';
import formIcon from '../../assets/images/icons/form.svg';
import UserIcon from '../../assets/images/icons/users.svg';
import ecommerceIcon from '../../assets/images/icons/ecommerce.svg';

const SideMenu = ({ toggleDrawerWidth, isDrawerOpen, isSmallScreen }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const userDetail = localStorage.getItem('user');
    const parsedUser = userDetail ? JSON.parse(userDetail) : null;
    const [user, setUser] = useState(parsedUser);
    const [expanded, setExpanded] = useState(false);
    const profile = [
        'companies',
        'states',
        'counties',
        'categories',
        'profile',
    ];

    const handleCloseSidebar = () => {
        if (isSmallScreen) {
            toggleDrawerWidth();
        }
    };
    useEffect(() => {
        const matchesProfile = profile.some(item =>
            location.pathname.includes(item)
        );
        if (matchesProfile) {
            setExpanded('panel2');
        } else if (location.pathname.includes('form-entries')) {
            setExpanded('panel3');
        } else if (
            location.pathname.includes('plan') ||
            location.pathname.includes('orders') ||
            location.pathname.includes('view-order')
        ) {
            setExpanded('panel4');
        } else if (
            location.pathname.includes('user') ||
            location.pathname.includes('my-account')
        ) {
            setExpanded('panel5');
        } else {
            setExpanded(false);
        }
    }, [location.pathname]);

    const handleChange = panel => (event, isExpanded) => {
        if (panel === 'panel3') {
            navigate('/form-entries');
            setExpanded(panel);
        } else {
            setExpanded(isExpanded ? panel : false);
        }
    };

    const isActive = path => {
        return location.pathname.includes(path);
    };
    return (
        <>
            <Drawer
                anchor="left"
                className={`sidebar-wrapper ${
                    isDrawerOpen ? 'open' : 'closed sidebar-closed'
                }`}
                variant="persistent"
                open={isDrawerOpen}
            >
                <Box className="list-wrap">
                    <Box className="logo-box" sx={{ display: 'flex' }}>
                        <img
                            src={logo}
                            alt="logo-main"
                            loading="lazy"
                            className={
                                !isSmallScreen ? 'large-logo' : 'small-logo'
                            }
                            style={{ border: '0px solid white' }}
                            onClick={() => navigate('/dashboard')}
                        />
                        {isDrawerOpen && isSmallScreen && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                className="toggle-btn"
                                sx={{ border: '0px solid grey' }}
                                onClick={toggleDrawerWidth}
                            >
                                <MenuOpenIcon
                                    className={'close-menu'}
                                    fill="#000"
                                />
                            </IconButton>
                        )}
                    </Box>
                    <Box
                        className="Menu-list-wrapper-sidebar"
                        sx={{ paddingTop: '8px' }}
                    >
                        <Accordion
                            className="menu-list"
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                            onClick={() => {
                                handleCloseSidebar();
                                navigate('/dashboard');
                            }}
                        >
                            <AccordionSummary
                                // expandIcon={<ChevronRightRoundedIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className="menu-name">
                                    <img src={dashboardIcon} alt="logo-main" />
                                    Dashboard
                                </Typography>
                            </AccordionSummary>
                        </Accordion>

                        <Accordion
                            className="menu-list"
                            expanded={expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                        >
                            <AccordionSummary
                                expandIcon={<ChevronRightRoundedIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography className="menu-name">
                                    <img src={profileIcon} alt="logo-main" />
                                    Profiles
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List className="sidebar first-menu-list">
                                    <ListItem
                                        onClick={() => {
                                            handleCloseSidebar();
                                            navigate('/all-profiles');
                                        }}
                                    >
                                        <ListItemButton
                                            className={
                                                isActive('profile')
                                                    ? 'active-menu'
                                                    : ''
                                            }
                                        >
                                            <ListItemText
                                                primary={'All Profiles'}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    {user?.role !== 'user' && (
                                        <ListItem
                                            onClick={() => {
                                                handleCloseSidebar();
                                                navigate('/categories');
                                            }}
                                        >
                                            <ListItemButton
                                                className={
                                                    isActive('categories')
                                                        ? 'active-menu'
                                                        : ''
                                                }
                                            >
                                                <ListItemText
                                                    primary={'Categories'}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    {user?.role !== 'user' && (
                                        <ListItem
                                            onClick={() => {
                                                handleCloseSidebar();
                                                navigate('/companies');
                                            }}
                                        >
                                            <ListItemButton
                                                className={
                                                    isActive('companies')
                                                        ? 'active-menu'
                                                        : ''
                                                }
                                            >
                                                <ListItemText
                                                    primary={'Companies'}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    {user?.role !== 'user' && (
                                        <ListItem
                                            onClick={() => {
                                                handleCloseSidebar();
                                                navigate('/states');
                                            }}
                                        >
                                            <ListItemButton
                                                className={
                                                    isActive('states')
                                                        ? 'active-menu'
                                                        : ''
                                                }
                                            >
                                                <ListItemText
                                                    primary={'States'}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    {user?.role !== 'user' && (
                                        <ListItem
                                            onClick={() => {
                                                handleCloseSidebar();
                                                navigate('/counties');
                                            }}
                                        >
                                            <ListItemButton
                                                className={
                                                    isActive('counties')
                                                        ? 'active-menu'
                                                        : ''
                                                }
                                            >
                                                <ListItemText
                                                    primary={'Counties'}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        {user?.role !== 'user' && (
                            <Accordion
                                className="menu-list"
                                expanded={expanded === 'panel3'}
                                onChange={handleChange('panel3')}
                            >
                                <AccordionSummary
                                    // expandIcon={<ChevronRightRoundedIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography
                                        className="menu-name"
                                        onClick={() => {
                                            handleCloseSidebar();
                                            navigate('/form-entries');
                                        }}
                                    >
                                        <img src={formIcon} alt="logo-main" />
                                        Form
                                    </Typography>
                                </AccordionSummary>
                            </Accordion>
                        )}

                        <Accordion
                            className="menu-list"
                            expanded={expanded === 'panel4'}
                            onChange={handleChange('panel4')}
                        >
                            <AccordionSummary
                                expandIcon={<ChevronRightRoundedIcon />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography className="menu-name">
                                    <img src={ecommerceIcon} alt="logo-main" />
                                    E-Commerce
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List className="sidebar first-menu-list">
                                    <ListItem
                                        onClick={() => {
                                            handleCloseSidebar();
                                            navigate('/orders');
                                        }}
                                    >
                                        <ListItemButton
                                            className={
                                                isActive('order')
                                                    ? 'active-menu'
                                                    : ''
                                            }
                                        >
                                            <ListItemText primary={'Orders'} />
                                        </ListItemButton>
                                    </ListItem>
                                    {user?.role !== 'user' && (
                                        <ListItem
                                            onClick={() => {
                                                handleCloseSidebar();
                                                navigate('/plans');
                                            }}
                                        >
                                            <ListItemButton
                                                className={
                                                    isActive('plan')
                                                        ? 'active-menu'
                                                        : ''
                                                }
                                            >
                                                <ListItemText
                                                    primary={'Plans'}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion
                            className="menu-list"
                            expanded={expanded === 'panel5'}
                            onChange={handleChange('panel5')}
                        >
                            <AccordionSummary
                                // expandIcon={<ChevronRightRoundedIcon />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                {user?.role === 'admin' && (
                                    <Typography
                                        className="menu-name"
                                        onClick={() => {
                                            handleCloseSidebar();
                                            navigate('/users');
                                        }}
                                    >
                                        <img src={UserIcon} alt="logo-main" />
                                        Users
                                    </Typography>
                                )}
                                {user?.role !== 'admin' && (
                                    <Typography
                                        className="menu-name"
                                        onClick={() => {
                                            handleCloseSidebar();
                                            navigate('/my-account');
                                        }}
                                    >
                                        <img src={UserIcon} alt="logo-main" />
                                        My Account
                                    </Typography>
                                )}
                            </AccordionSummary>
                        </Accordion>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default SideMenu;
