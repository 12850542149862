import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import NotFoundImage from '../../assets/images/404.png'

function NotFound() {
    const navigate = useNavigate();
    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={6}
            >
                <img
                    src={NotFoundImage}
                    alt="NotFoundImage"
                    className='notfound-img'
                    style={{ width: '25%', height: '15%'}}
                />
            </Box>
            <Box className="content-header-left" mt={6} mb={2}>
                <Typography variant="h2" component="h2" align="center" className='notfound-title'>
                    Page not found
                </Typography>
            </Box>
            <Typography variant="body1" align="center" className='notfound-desc'>
                Oops! The page you are looking for does not exist.<br/> It might have
                been moved or deleted.
            </Typography>
            <Box display="flex" justifyContent="center" mt={2}>
                <Button
                    variant="contained"
                    className="btn primary-btn"
                    size="large"
                    onClick={() => navigate('/')}
                >
                    Back to Home
                </Button>
            </Box>
        </>
    );
}

export default NotFound;
