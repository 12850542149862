import React from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './protectedRoutes';
import Dashboard from '../pages/dashboard';
import Profiles from '../pages/profiles';
import Companies from '../pages/companies';
import Orders from '../pages/orders';
import Plans from '../pages/plans';
import MyAccount from '../pages/myAccount';
import NotFound from '../components/common/404';
import States from '../pages/states';
import Counties from '../pages/counties';
import Categories from '../pages/categories';
import AddEditProfiles from '../pages/profiles/addEditProfile';
import ViewProfile from '../pages/profiles/viewProfile';
import FormEntries from '../pages/formEntries';
import AddEditPlan from '../pages/plans/addEditPlan';
import Users from '../pages/users';
import AddEditUser from '../pages/users/addEditUser';
import Logout from '../pages/logout';
import ViewUser from '../pages/users/viewUser';
import ViewOrder from '../pages/orders/viewOrder';
import ViewFormEntry from '../pages/formEntries/viewFormEntries';

const Routes = () => {
    return (
        <ReactRoutes>
            <Route
                path="/"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/all-profiles"
                element={
                    <ProtectedRoute>
                        <Profiles />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/add-profile"
                element={
                    <ProtectedRoute>
                        <AddEditProfiles />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/edit-profile/:slug"
                element={
                    <ProtectedRoute>
                        <AddEditProfiles />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/view-profile/:slug"
                element={
                    <ProtectedRoute>
                        <ViewProfile />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/companies/edit/:slug"
                element={
                    <ProtectedRoute>
                        <Companies />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/companies"
                element={
                    <ProtectedRoute>
                        <Companies />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/categories/edit/:slug"
                element={
                    <ProtectedRoute>
                        <Categories />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/categories"
                element={
                    <ProtectedRoute>
                        <Categories />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/states"
                element={
                    <ProtectedRoute>
                        <States />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/counties"
                element={
                    <ProtectedRoute>
                        <Counties />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/form-entries"
                element={
                    <ProtectedRoute>
                        <FormEntries />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/form-entries/:form/:id"
                element={
                    <ProtectedRoute>
                        <ViewFormEntry />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/orders"
                element={
                    <ProtectedRoute>
                        <Orders />
                    </ProtectedRoute>
                }
            />
             <Route
                path="/view-order/:id"
                element={
                    <ProtectedRoute>
                        <ViewOrder />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/plans"
                element={
                    <ProtectedRoute>
                        <Plans />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/add-plan"
                element={
                    <ProtectedRoute>
                        <AddEditPlan />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/edit-plan/:id"
                element={
                    <ProtectedRoute>
                        <AddEditPlan />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/edit-user/:id"
                element={
                    <ProtectedRoute>
                        <AddEditUser />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/view-user/:id"
                element={
                    <ProtectedRoute>
                        <ViewUser />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/add-user"
                element={
                    <ProtectedRoute>
                        <AddEditUser />
                    </ProtectedRoute>
                }
            />
             <Route
                path="/users"
                element={
                    <ProtectedRoute>
                        <Users />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/my-account"
                element={
                    <ProtectedRoute>
                        <MyAccount />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/logout"
                element={
                    <ProtectedRoute>
                        <Logout />
                    </ProtectedRoute>
                }
            />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
        </ReactRoutes>
    );
};

export default Routes;
