import axios from "axios"
import { LocalUtils } from "./localStorage"
import { toast } from "react-toastify"
// import { userLogout } from "../redux/actions"

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Accept: "application/json",
    }
})


api.interceptors.request.use(
    (request) => {
        request.headers = {
            ...request.headers,
            // Authorization: `token ${LocalUtils.getToken()}`
        }
        return request
    },
    (error) => {
        console.log(error)
        return error
    }
)

export const formDataApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: `Basic ${LocalUtils.getToken()}`
    }
})

formDataApi.interceptors.request.use(
    (request) => {
        request.headers = {
            ...request.headers,
            Authorization: `Basic ${LocalUtils.getToken()}`
        }
        return request
    },
    (error) => {
        console.log(error)
        return error
    }
)

formDataApi.interceptors.response.use(
    function (response) {
        return response
    },
    (error) => {
        if (error?.response?.data?.status === 401) {
            toast.error(error?.response?.data?.message || error?.message)
        } else if (error?.response?.data?.status === 401) {
            toast.error(error?.response?.data?.message || error?.message)
            // return userLogout()
        }
        return Promise.reject(error)
    }
)


export const tokenapi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Accept: "application/json",
        Authorization: `Token ${LocalUtils.getToken()}`,
        // 'Access-Control-Allow-Origin': '*',
    }
})

tokenapi.interceptors.request.use(
    (request) => {
        request.headers = {
            ...request.headers,
            Authorization: `Token ${LocalUtils.getToken()}`,
        }
        return request
    },
    (error) => {
        console.log(error)
        return error
    }
)

tokenapi.interceptors.response.use(
    function (response) {
        return response
    },
    (error) => {
        if (error?.response?.data?.status === 403) {
            toast.error(error?.response?.data?.detail || error?.response?.data?.message || error?.message)
        } else if (error?.response?.status === 401) {
            toast.error(error?.response?.data?.detail || error?.response?.data?.message ||  error?.message)
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = `${process.env.REACT_APP_FRONTEND_SITE_URL}/logout`;
            // return userLogout()
        }
        return Promise.reject(error)
    }
)


