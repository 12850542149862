import './styles/global.scss';
import './styles/dashboard.scss';
import './styles/profiles.scss';
import './styles/companies.scss';
import './styles/orders.scss';
import './styles/plans.scss';
import './styles/myAccount.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import Routes from '../src/routes/index';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function App() {
    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </>
    );
}

export default App;
