import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    Stack,
    Grid,
    createTheme,
    useMediaQuery,
    Modal,
    Fade,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteTrash from '../../assets/images/icons/delete.svg';
import { toast } from 'react-toastify';
import { deleteCounty, getCountries } from '../../utils/apiConstants';
import { handleSelectChange, pagintaionPageCount } from '../../utils/constants';
import Pagination from '../../components/common/pagination';
import Loader from '../../components/common/loader';
import { useDebounce } from '../../utils/useDebounce';
import {
    ArrowDownward,
    ArrowUpward,
    SwapVert ,
} from '@mui/icons-material';

const theme = createTheme();

const Counties = () => {
    const [loader, setLoader] = useState(true);
    const [countries, setCountries] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedSlugs, setSelectedSlugs] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const trashModalOpen = () => setOpen(true);
    const trashModalClose = () => setOpen(false);

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedSlugs([]);
        } else {
            const allSlugs = countries.map(country => country.id);
            setSelectedSlugs(allSlugs);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleRowCheckboxChange = id => {
        if (selectedSlugs.includes(id)) {
            setSelectedSlugs(selectedSlugs.filter(item => item !== id));
        } else {
            setSelectedSlugs([...selectedSlugs, id]);
        }
    };

    const getCountryList = async (newSortOrder = sortOrder,column = sortBy) => {
        setLoader(true);
        try {
            const response = await getCountries(
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                searchValue,
                sortBy || column ? (newSortOrder || sortOrder)  === 'asc' ? column || sortBy : `-`+column || sortBy : sortBy
            );
            if (response?.status == 200) {
                setCountries(response?.data?.results?.data);
                setTotalCount(response?.data?.count || 0);
                setTotalPage(
                    Math.ceil(response?.data?.count / showPerPage) || 0
                );
            }
        } catch (error) {
            toast.error(error?.response?.data?.detail || error?.response?.data?.message || error.message);
        }
        setLoader(false);
    };

    const fetchData = pageSize => {
        setSelectedPage(1)
        setShowPerPage(pageSize);
    };

    useEffect(() => {
        getCountryList();
    }, [debouncedSearchTerm, showPerPage, selectedPage]);

    const [openRow, setOpenRow] = useState(null);

    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    const handleSort = (column) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getCountryList(newSortOrder,column);
    };

    const deleteCountyById = async () => {
        setLoader(true);
        try {
            if (selectedSlugs) {
                const responses = await deleteCounty(selectedSlugs.join(','));

                if (responses?.data?.status == 200) {
                    toast.success(responses?.data?.message);
                    setSelectedPage(1)
                    setOpen(false);
                    setHeaderChecked(!headerChecked);
                    setSelectedSlugs([])
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data?.detail || error.message);
        }
        setLoader(false);
    };

    const getSortIcon = (column) => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward size={20} />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward size={20} />;
        } else {
            return <SwapVert size={20} />;
        }
    };

    return (
        <>
            <Box className="content-header companies-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Counties
                    </Typography>
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search Counties"
                            onChange={e => {
                                setSearchValue(e.target.value);setSelectedPage(1)
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="companies-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            <TableCell className="table-checkbox-width">
                                                <Checkbox
                                                    className="checkbox-ui"
                                                    checked={headerChecked}
                                                    onChange={
                                                        handleHeaderCheckboxChange
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell onClick={()=>{setSortBy('state');handleSort('state')}}>State {getSortIcon('state')}</TableCell>
                                            <TableCell onClick={()=>{setSortBy('name');handleSort('name')}}>County {getSortIcon('name')}</TableCell>
                                            <TableCell>URL</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : countries?.length > 0 ? (
                                            countries?.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        onClick={e =>
                                                            isSmallScreen &&
                                                            e.target.type !==
                                                                'checkbox' &&
                                                            handleRowClick(
                                                                index
                                                            )
                                                        }
                                                        className={
                                                            openRow === index
                                                                ? 'open'
                                                                : ''
                                                        }
                                                    >
                                                        <TableCell className="table-checkbox-width">
                                                            <Checkbox
                                                                className="checkbox-ui"
                                                                checked={selectedSlugs.includes(
                                                                    row?.id
                                                                )}
                                                                onChange={() =>
                                                                    handleRowCheckboxChange(
                                                                        row?.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="show-text-arrow-content">
                                                                {row?.state}
                                                                {openRow ===
                                                                index ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {row?.name}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {row?.url}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                gap={3}
                                                                className="action-wrapper"
                                                            >
                                                                <Button
                                                                    className="btn delete-btn"
                                                                    onClick={() => {
                                                                        setSelectedSlugs(
                                                                            [
                                                                                ...selectedSlugs,
                                                                                row?.id,
                                                                            ]
                                                                        );
                                                                        trashModalOpen();
                                                                    }}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    {openRow === index && (
                                                        <TableRow className="accordion-content">
                                                            <TableCell
                                                                colSpan={4}
                                                            >
                                                                <Box className="accordion-box">
                                                                    <Stack
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="MuiLink-root fw-600"
                                                                        >
                                                                            State:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row?.state
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Country:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row?.name
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            URL:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row?.url
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Stack
                                                                            direction={
                                                                                'row'
                                                                            }
                                                                            gap={
                                                                                3
                                                                            }
                                                                            className="action-wrapper"
                                                                        >
                                                                            <Button
                                                                                className="btn delete-btn"
                                                                                onClick={() => {
                                                                                    setSelectedSlugs(
                                                                                        [
                                                                                            ...selectedSlugs,
                                                                                            row?.id,
                                                                                        ]
                                                                                    );
                                                                                    trashModalOpen();
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon />
                                                                            </Button>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={trashModalClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-ui">
                        <Box className="modal-body">
                            <Box className="confirmation-message-wrapper">
                                <Box className="image-wrapper">
                                    <img src={DeleteTrash} />
                                </Box>
                                <Box className="confirm-content-wrapper">
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        className="confirm-title"
                                    >
                                        Are you sure want to delete this county?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        className="confirm-para"
                                    >
                                        This action is permanent and cannot be
                                        undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="modal-footer-wrapper">
                                <Button
                                    className="btn red-btn"
                                    onClick={() => deleteCountyById()}
                                >
                                    Delete
                                </Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={trashModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default Counties;
