import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SwitchField from '../../components/common/switchField';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfileDetailBySlug } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import { formatTime, getFormattedDateTime } from '../../utils/constants';
import Loader from '../../components/common/loader';

const ViewProfile = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [profileDetail, setProfileDetail] = useState([]);

    const getProfileDetailsBySlug = async slug => {
        setIsDetailLoading(true);
        try {
            const response = await getProfileDetailBySlug(slug);
            if (response?.status === 200) {
                setProfileDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setIsDetailLoading(false);
    };

    useEffect(() => {
        if (slug) {
            getProfileDetailsBySlug(slug);
        }
    }, [slug]);

    return (
        <>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        View Profile
                    </Typography>
                </Box>
            </Box>
            <Box className="content-layout mt-22 form-field-wrapper">
                {isDetailLoading ? (
                    <Box className="h-70vh">
                        <Loader />
                    </Box>
                ) : (
                    <>
                        {' '}
                        <Grid container spacing={3}>
                            <Grid item lg={8} md={7} sm={12} xs={12}>
                                <Stack gap={3} className="card-height">
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Profile Details
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Title
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.title ||
                                                            '-'}{' '}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Slug
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.slug ||
                                                            '-'}{' '}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Category
                                                    </Typography>
                                                    <Box className="field-count-wrapper">
                                                        {/* <Box className="field-count">
                                                    1
                                                </Box> */}
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                            {profileDetail
                                                                ?.category_name?.[0]
                                                                ?.name ||
                                                                '-'}{' '}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Profile
                                                    </Typography>
                                                    <Box
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                profileDetail?.content ||
                                                                '-',
                                                        }}
                                                    ></Box>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Profile Image
                                                    </Typography>
                                                    <Box className="field-browse-wrapper">
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                          {profileDetail?.featured_image ? (<img src={profileDetail?.featured_image} alt="featured_image" style={{width:'50%'}}/>)  : '-'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Background Header
                                                    </Typography>
                                                    <Box className="field-browse-wrapper">
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                             {profileDetail?.background_header ? (<img src={profileDetail?.background_header} alt="featured_image" style={{width:'50%'}}/>)  : '-'}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    SEO Meta
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Meta Title
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.meta_title ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Meta Description
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.meta_description ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Meta Keywords
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {
                                                            profileDetail?.meta_keywords
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Additional Content
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Company
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_company
                                                            ?.name || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Tag Line
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_tag_line ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Overview
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_overview ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Website
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_website ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Phone
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_phone || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Profile Contact Email
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_contact_email ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Owner/General Manager's
                                                        Name
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_owner || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Submitter's Email
                                                        Address
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_submitter_email ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Submitter's Phone Number
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_submitter_phone ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Keyword 1
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_keyword_1 ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Keyword 2
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_keyword_2 ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Keyword 3
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.add_content
                                                            ?.add_keyword_3 ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Address
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Full Address
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_full_address ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Address 1
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_address_1 ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Address 2
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_address_2 ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Address 3
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_address_3 ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        City
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_city || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        State
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_state || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Zip Code
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_zip || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        County
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_county ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Latitude
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_latitude ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Longitude
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_longitude ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Other State
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_other_state ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={6}
                                                md={12}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Country
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.address
                                                            ?.addr_country ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item lg={4} md={5} sm={12} xs={12}>
                                <Stack gap={3} className="card-height">
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Status Information
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Status
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.info
                                                            ?.post_status ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Show Contact Form
                                                    </Typography>
                                                    <SwitchField
                                                        checked={
                                                            profileDetail?.info
                                                                ?.show_contact_form
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Profile Claimed
                                                    </Typography>
                                                    <SwitchField
                                                        checked={
                                                            profileDetail?.info
                                                                ?.claim_status
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Disable Adsense
                                                    </Typography>
                                                    <SwitchField
                                                        checked={
                                                            profileDetail?.info
                                                                ?.disable_adsense
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Allow Comments/Reviews
                                                    </Typography>
                                                    <SwitchField
                                                        checked={
                                                            profileDetail?.info
                                                                ?.comment_status
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Created At
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        className="date-text"
                                                    >
                                                        {profileDetail?.created_at
                                                            ? getFormattedDateTime(
                                                                  profileDetail?.created_at
                                                              )
                                                            : '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Last Updated
                                                    </Typography>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        className="date-text"
                                                    >
                                                        {profileDetail?.updated_at
                                                            ? getFormattedDateTime(
                                                                  profileDetail?.updated_at
                                                              )
                                                            : '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Buy Now Button
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Button Text
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.tab_hours
                                                            ?.tab_buynow_button_text ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Outgoing URL
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.tab_hours
                                                            ?.tab_buynow_outgoing_url ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Shop URL
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.info
                                                            ?.shop_url || '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Background Color
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_buynow_background_color ||
                                                                '-'}
                                                        </Typography>
                                                        <Box
                                                        className="color-picker-wrapper"
                                                        sx={{
                                                            backgroundColor:
                                                                profileDetail
                                                                    ?.tab_hours
                                                                    ?.tab_buynow_background_color,
                                                        }}
                                                    ></Box>
                                                    </Box>
                                                   
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Text Color
                                                    </Typography>
                                                    <Box
                                                        sx={{
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <Typography
                                                            className="input-ui"
                                                            variant="body1"
                                                            component="div"
                                                        >
                                                            {
                                                                profileDetail
                                                                    ?.tab_hours
                                                                    ?.tab_buynow_text_color
                                                            }
                                                        </Typography>
                                                        <Box
                                                            className="color-picker-wrapper"
                                                            sx={{
                                                                backgroundColor:
                                                                    profileDetail
                                                                        ?.tab_hours
                                                                        ?.tab_buynow_text_color,
                                                            }}
                                                        ></Box>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Click Tracking
                                                        Identification
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail
                                                            ?.tab_hours
                                                            ?.tab_buynow_triggered_event ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Icon Link
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Icon Link URL
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.info
                                                            ?.icon_link_url ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Image Alt
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.info
                                                            ?.icon_link_image_alt ||
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper radio-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Follow
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.info
                                                            ?.icon_link_follow ===
                                                        'follow'
                                                            ? 'follow'
                                                            : 'nofollow'}{' '}
                                                        {/* Replace with actual data */}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper radio-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Target
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        {profileDetail?.info
                                                            ?.icon_link_target ===
                                                        'self'
                                                            ? '_self'
                                                            : '_blank'}{' '}
                                                        {/* Replace with actual data */}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Opening Hours
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Monday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_monday
                                                                ? profileDetail
                                                                      .tab_hours
                                                                      .tab_hours_monday !==
                                                                  'Closed'
                                                                    ? profileDetail?.tab_hours?.tab_hours_monday
                                                                          ?.split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          ?.split(
                                                                                              ':'
                                                                                          )
                                                                                          ?.map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Tuesday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_tuesday
                                                                ? profileDetail
                                                                      .tab_hours
                                                                      .tab_hours_tuesday !==
                                                                  'Closed'
                                                                    ? profileDetail?.tab_hours?.tab_hours_tuesday
                                                                          ?.split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          ?.split(
                                                                                              ':'
                                                                                          )
                                                                                          ?.map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Wednesday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_wednesday
                                                                ? profileDetail
                                                                      ?.tab_hours
                                                                      ?.tab_hours_wednesday !==
                                                                  'Closed'
                                                                    ? profileDetail?.tab_hours?.tab_hours_wednesday
                                                                          ?.split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          ?.split(
                                                                                              ':'
                                                                                          )
                                                                                          .map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Thursday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_thursday
                                                                ? profileDetail
                                                                      .tab_hours
                                                                      .tab_hours_thursday !==
                                                                  'Closed'
                                                                    ? profileDetail?.tab_hours?.tab_hours_thursday
                                                                          ?.split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          ?.split(
                                                                                              ':'
                                                                                          )
                                                                                          ?.map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Friday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_friday
                                                                ? profileDetail
                                                                      .tab_hours
                                                                      .tab_hours_friday !==
                                                                  'Closed'
                                                                    ? profileDetail.tab_hours.tab_hours_friday
                                                                          ?.split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          ?.split(
                                                                                              ':'
                                                                                          )
                                                                                          ?.map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Saturday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_saturday
                                                                ? profileDetail
                                                                      .tab_hours
                                                                      .tab_hours_saturday !==
                                                                  'Closed'
                                                                    ? profileDetail?.tab_hours?.tab_hours_saturday
                                                                          ?.split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          ?.split(
                                                                                              ':'
                                                                                          )
                                                                                          ?.map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper switch-field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Sunday
                                                    </Typography>
                                                    <Typography
                                                        className="input-ui"
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <React.Fragment>
                                                            {profileDetail
                                                                ?.tab_hours
                                                                ?.tab_hours_sunday
                                                                ? profileDetail
                                                                      .tab_hours
                                                                      .tab_hours_sunday !==
                                                                  'Closed'
                                                                    ? profileDetail?.tab_hours?.tab_hours_sunday
                                                                          .split(
                                                                              '-'
                                                                          )
                                                                          ?.map(
                                                                              (
                                                                                  time,
                                                                                  index
                                                                              ) => {
                                                                                  const trimmedTime =
                                                                                      time?.trim();
                                                                                  const [
                                                                                      hours,
                                                                                      minutes,
                                                                                  ] =
                                                                                      trimmedTime
                                                                                          .split(
                                                                                              ':'
                                                                                          )
                                                                                          .map(
                                                                                              part =>
                                                                                                  parseInt(
                                                                                                      part,
                                                                                                      10
                                                                                                  )
                                                                                          );

                                                                                  if (
                                                                                      index ===
                                                                                      0
                                                                                  ) {
                                                                                      return `${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  } else {
                                                                                      return ` to ${formatTime(
                                                                                          hours,
                                                                                          minutes
                                                                                      )}`;
                                                                                  }
                                                                              }
                                                                          )
                                                                          .join(
                                                                              ''
                                                                          )
                                                                    : 'Closed'
                                                                : '-'}
                                                        </React.Fragment>
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Box className="footer-wrapper">
                            <Box className="btn-wrapper">
                                <Button
                                    className="btn secondary-btn"
                                    onClick={() => navigate('/all-profiles')}
                                >
                                    Back
                                </Button>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};

export default ViewProfile;
