// DragAndDropUpload.js

import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const DragAndDropUpload = ({ 
  onDrop, 
  accept = 'image/*', 
  multiple = true, 
  previewWidth = 200, 
  iconSize = 50,
  placeholderText = 'Drag and drop some files here, or click to select files'
}) => {
  const [files, setFiles] = useState([]);
  const inputRef = useRef();

  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    if (onDrop) {
      onDrop(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept,
    multiple
  });

//   const openFileDialog = () => {
//     inputRef.current.click();
//   };

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: '2px dashed grey',
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer'
      }}
    >
      <input {...getInputProps()} ref={inputRef} style={{ display: 'none' }} />
      <CloudUploadIcon sx={{ fontSize: iconSize }}/>
      <Typography variant="h6">
        {placeholderText}
      </Typography>
      {/* <Button
        variant="outlined"
        onClick={openFileDialog}
        sx={{ mt: 2 }}
      >
        Browse
      </Button> */}
      {files.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6">Preview:</Typography>
          {files.map(file => (
            <Box key={file.name} sx={{ mt: 2 }}>
              <img src={file.preview} alt={file.name} width={previewWidth} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DragAndDropUpload;
