// UploadModal.js

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DragAndDropUpload from './DragAndDropUpload';

const UploadModal = ({ open,row = {},imageType = '', onClose, onDrop }) => {
    const handleDrop = (files) => {
        onDrop(files, row, imageType);
      };
    
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <DragAndDropUpload onDrop={handleDrop} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadModal;
