import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDebounce } from '../../utils/useDebounce';
import { Button, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/system';
import { toast } from 'react-toastify';
import { getOrders } from '../../utils/apiConstants';
import Pagination from '../../components/common/pagination';
import {
    getFormattedDateTime,
    handleSelectChange,
    pagintaionPageCount,
} from '../../utils/constants';
import Loader from '../../components/common/loader';
import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelSubscription from './cancelSubscription';
// TABBING UI FOR TABLE
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const theme = createTheme();

const Orders = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [orders, setOrders] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [totalAllCount, setTotalAllCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const [statusCounts, setStatusCounts] = useState({});
    const [value, setValue] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const userDetail = localStorage.getItem('user');
    const parsedUser = userDetail ? JSON.parse(userDetail) : null;
    const [user, setUser] = useState(parsedUser);
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [openRow, setOpenRow] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedIds([]);
        } else {
            const allIds = orders.map(item => item.id);
            setSelectedIds(allIds);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleRowCheckboxChange = id => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter(item => id !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    const getOrder = value => {
        switch (value) {
            case 1:
                return 'completed';
            case 2:
                return 'pending';
            case 3:
                return 'cancelled';

            default:
                return 'all';
        }
    };

    const getOrderList = async (newSortOrder = sortOrder, column = sortBy) => {
        setLoader(true);
        try {
            const response = await getOrders(
                getOrder(value),
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                searchValue,
                sortBy || column
                    ? (newSortOrder || sortOrder) === 'asc'
                        ? column || sortBy
                        : `-` + column || sortBy
                    : sortBy
            );
            if (response?.status == 200) {
                setOrders(response?.data?.results?.data || []);
                setTotalCount(response?.data?.count || 0);
                setStatusCounts(response?.data?.results?.status_counts);
                setTotalAllCount(response?.data?.results?.total_orders || 0);
                setTotalPage(
                    Math.ceil(response?.data?.count / showPerPage) || 0
                );
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    error?.response?.data?.error
            );
        }
        setLoader(false);
    };

    const handleSort = column => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getOrderList(newSortOrder, column);
    };

    const getSortIcon = column => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward />;
        } else {
            return <SwapVert size={22} />;
        }
    };

    const fetchData = pageSize => {
        setSelectedPage(1);
        setShowPerPage(pageSize);
    };

    useEffect(() => {
        getOrderList();
    }, [debouncedSearchTerm, showPerPage, selectedPage, value]);

    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    return (
        <>
            <Box className="content-header order-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Orders
                    </Typography>
                    {/* <Button className="outline-btn btn">
                        Add New
                    </Button> */}
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search Order"
                            onChange={e => {
                                setSearchValue(e.target.value);
                                setSelectedPage(1);
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Box className="tab-ui-wrapper">
                    <Tabs
                        className="tab-button-wrapper"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label={`All  (${totalAllCount || 0})`}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={`Completed (${
                                statusCounts?.completed || 0
                            })`}
                            {...a11yProps(1)}
                        />
                        <Tab
                            label={`Pending (${statusCounts?.pending || 0})`}
                            {...a11yProps(2)}
                        />
                        <Tab
                            label={`Cancelled (${
                                statusCounts?.cancelled || 0
                            })`}
                            {...a11yProps(3)}
                        />
                    </Tabs>
                    <CustomTabPanel value={value} index={value}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="order-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            {/* <TableCell className="table-checkbox-width">
                                                <Checkbox className="checkbox-ui" />
                                            </TableCell> */}
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('id');
                                                    handleSort('id');
                                                }}
                                            >
                                                Order ID {getSortIcon('id')}{' '}
                                            </TableCell>
                                            <TableCell>Full Name</TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('email');
                                                    handleSort('email');
                                                }}
                                            >
                                                Email Address{' '}
                                                {getSortIcon('email')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('phone_number');
                                                    handleSort('phone_number');
                                                }}
                                            >
                                                Phone Number{' '}
                                                {getSortIcon('phone_number')}{' '}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy(
                                                        'stripe_subscription_id'
                                                    );
                                                    handleSort(
                                                        'stripe_subscription_id'
                                                    );
                                                }}
                                            >
                                                Stripe Subscription ID{' '}
                                                {getSortIcon(
                                                    'stripe_subscription_id'
                                                )}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('status');
                                                    handleSort('status');
                                                }}
                                            >
                                                Status {getSortIcon('status')}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('purchased_at');
                                                    handleSort('purchased_at');
                                                }}
                                            >
                                                Purchased At{' '}
                                                {getSortIcon('purchased_at')}
                                            </TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={8}
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : orders?.length > 0 ? (
                                            orders.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        onClick={e => {
                                                            if (
                                                                isSmallScreen &&
                                                                e.target
                                                                    .type !==
                                                                    'checkbox'
                                                            ) {
                                                                handleRowClick(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                        className={
                                                            openRow === index
                                                                ? 'open'
                                                                : ''
                                                        }
                                                    >
                                                        {/* <TableCell className="table-checkbox-width">
                                                            <Checkbox className="checkbox-ui" />
                                                        </TableCell> */}
                                                        <TableCell>
                                                            {' '}
                                                            <Box className="show-text-arrow-content">
                                                                {' '}
                                                                {
                                                                    row
                                                                        ?.customer_details
                                                                        ?.id
                                                                }{' '}
                                                                {openRow ===
                                                                index ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                            className="hide-td"
                                                        >
                                                            {
                                                                row
                                                                    ?.customer_details
                                                                    ?.full_name
                                                            }
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {
                                                                row
                                                                    ?.customer_details
                                                                    ?.email
                                                            }
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            {
                                                                row
                                                                    ?.customer_details
                                                                    ?.phone_number
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                            className="hide-td"
                                                        >
                                                            <Link
                                                                href="#"
                                                                className="fw-400"
                                                            >
                                                                {
                                                                    row
                                                                        ?.customer_details
                                                                        ?.stripe_subscription_id
                                                                }
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Box
                                                                className={`status ${
                                                                    row?.status ===
                                                                    'Cancelled'
                                                                        ? 'red'
                                                                        : row?.status ===
                                                                          'Completed'
                                                                        ? 'green'
                                                                        : 'yellow'
                                                                }`}
                                                            >
                                                                {row?.status}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                            className="hide-td"
                                                        >
                                                            {row?.purchased_at &&
                                                                getFormattedDateTime(
                                                                    row?.purchased_at
                                                                )}
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                gap={3}
                                                                className="action-wrapper"
                                                            >
                                                                {row?.status !==
                                                                    'Cancelled' && user?.role === 'user' && (
                                                                    <Button
                                                                        className="btn red-btn"
                                                                        sx={{
                                                                            width: 'auto!important',
                                                                        }}
                                                                        onClick={() => {
                                                                            setOpen(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                        Subscription
                                                                    </Button>
                                                                )}
                                                                <Button
                                                                    className="btn edit-btn"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/view-order/${row?.customer_details?.id}`
                                                                        )
                                                                    }
                                                                >
                                                                    <VisibilityIcon />
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    {openRow === index && (
                                                        <TableRow className="accordion-content">
                                                            <TableCell
                                                                colSpan={2}
                                                            >
                                                                <Box className="accordion-box">
                                                                    <Stack
                                                                        className=""
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Order
                                                                            ID:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row
                                                                                        ?.customer_details
                                                                                        ?.id
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Full
                                                                            Name:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row
                                                                                        ?.customer_details
                                                                                        ?.full_name
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Email
                                                                            Address:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row
                                                                                        ?.customer_details
                                                                                        ?.email
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Phone
                                                                            Number:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row
                                                                                        ?.customer_details
                                                                                        ?.phone_number
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Stripe
                                                                            Subscription
                                                                            ID:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                <Link
                                                                                    href="#"
                                                                                    className="fw-400"
                                                                                >
                                                                                    {
                                                                                        row
                                                                                            ?.customer_details
                                                                                            ?.stripe_subscription_id
                                                                                    }
                                                                                </Link>
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Status:
                                                                            <Box
                                                                                className={`status ${
                                                                                    row?.status ===
                                                                                    'Cancelled'
                                                                                        ? 'red'
                                                                                        : row?.status ===
                                                                                          'Completed'
                                                                                        ? 'green'
                                                                                        : 'yellow'
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    row?.status
                                                                                }
                                                                            </Box>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Purchased
                                                                            At:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {row?.purchased_at &&
                                                                                    getFormattedDateTime(
                                                                                        row?.purchased_at
                                                                                    )}
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Stack
                                                                            direction={
                                                                                'row'
                                                                            }
                                                                            gap={
                                                                                3
                                                                            }
                                                                            className="action-wrapper"
                                                                        >
                                                                            {row?.status !==
                                                                                'Cancelled' && user?.role === 'user' &&  (
                                                                                <Button
                                                                                    className="btn red-btn" 
                                                                                    sx={{
                                                                                        width: 'auto!important',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpen(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                    Subscription
                                                                                </Button>
                                                                            )}
                                                                            <Button
                                                                                className="btn edit-btn"
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/view-order/${row?.customer_details?.id}`
                                                                                    )
                                                                                }
                                                                            >
                                                                                <VisibilityIcon />
                                                                            </Button>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={8}
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </CustomTabPanel>
                </Box>
            </Box>
            <CancelSubscription open={open} onClose={handleClose} />
        </>
    );
};

export default Orders;
