import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserById } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/loader';

const ViewUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [userDetail, setUserDetail] = useState([]);

    const getUserDetailsById = async () => {
        setIsDetailLoading(true);
        try {
            const response = await getUserById(id);
            if (response?.status === 200) {
                setUserDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
        setIsDetailLoading(false);
    };

    useEffect(() => {
        if (id) {
            getUserDetailsById();
        }
    }, [id]);

    return (
        <Box>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        View User
                    </Typography>
                </Box>
            </Box>
            {isDetailLoading ? (
                <Box className="h-70vh">
                    <Loader />
                </Box>
            ) : (
                <Box className="content-layout mt-22 form-field-wrapper">
                    <Grid container spacing={3}>
                        <Grid item lg={8} md={7} sm={12} xs={12}>
                            <Box className="card-wrapper p-16">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            className="card-title"
                                        >
                                            User Details
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Display Name
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {userDetail?.displayname || '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Username
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {userDetail?.username || '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Email
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {userDetail?.email || '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={12} xs={12}>
                            <Box className="card-wrapper h-100">
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Role
                                        </Typography>
                                        <Typography
                                            className="input-ui"
                                            variant="body1"
                                            component="div"
                                        >
                                            {(userDetail?.role &&
                                                userDetail.role
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    userDetail.role.slice(1)) ||
                                                '-'}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Status
                                        </Typography>
                                        <Box
                                            className={`status ${
                                                userDetail?.is_active === true
                                                    ? 'green'
                                                    : userDetail?.is_active ===
                                                      false
                                                    ? 'dark'
                                                    : 'dark'
                                            }`}
                                        >
                                            {userDetail?.is_active === true
                                                ? 'Active'
                                                : 'Inactive' || '-'}
                                        </Box>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="footer-wrapper">
                        <Box className="btn-wrapper">
                            <Button
                                className="btn secondary-btn"
                                onClick={() => navigate('/users')}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default ViewUser;
