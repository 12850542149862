import React, { useState } from 'react';
import {
    Button,
    Modal,
    Box,
    TextField,
    Typography,
    Fade,
    CircularProgress,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { cancelSubscription } from '../../utils/apiConstants';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
    reason: Yup.string().required('Cancel Subscription Reason is required'),
});

const CancelSubscription = ({ open, onClose }) => {
    const initialValues = { reason: '' };
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const handleSave = async values => {
        setLoader(true);
        try {
            const data = new FormData();
            data.append('reason', values.reason);
            const response = await cancelSubscription(data);
            if (
                response?.data?.status == 201 ||
                response?.data?.status == 200
            ) {
                toast.success(response?.data?.message);
                navigate(`/orders`);
                onClose();
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error.response?.data?.detail ||
                    error.message
            );
        }
        setLoader(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
        >
            <Fade in={open}>
                <Box className="modal-wrapper modal-ui">
                    <Box className="modal-body">
                        <Box className="confirmation-message-wrapper">
                            <Box className="confirm-content-wrapper">
                                <Typography
                                    variant="body1"
                                    component="p"
                                    className="confirm-title"
                                >
                                    Are you sure you want to cancel the
                                    subscription?
                                </Typography>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSave}
                                >
                                    {({ isSubmitting, touched, errors }) => (
                                        <Form>
                                            <Box className="field-wrapper">
                                                <Typography
                                                    variant="body1"
                                                    component="label"
                                                    htmlFor="reason"
                                                >
                                                    Cancel Subscription Reason
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        className="astrick"
                                                    >
                                                        {' '}
                                                        *
                                                    </Typography>
                                                </Typography>
                                                <Field
                                                    as={TextField}
                                                    id="reason"
                                                    name="reason"
                                                    placeholder="Enter cancel subscription reason"
                                                    fullWidth
                                                    rows={3}
                                                    multiline
                                                    variant="outlined"
                                                    error={
                                                        touched.reason &&
                                                        Boolean(errors.reason)
                                                    }
                                                    helperText={
                                                        touched.reason &&
                                                        errors.reason
                                                    }
                                                    className="input-ui"
                                                />
                                            </Box>
                                            <Box className="modal-footer-wrapper">
                                                <Button
                                                    className="btn primary-btn"
                                                    type="submit"
                                                    disabled={loader}
                                                    endIcon={
                                                        loader && (
                                                            <CircularProgress
                                                                sx={{
                                                                    color: 'var(--white-color)',
                                                                }}
                                                                size={16}
                                                            />
                                                        )
                                                    }
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    className="btn secondary-btn"
                                                    onClick={onClose}
                                                    type="button"
                                                >
                                                    Cancel
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CancelSubscription;
