import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteTrash from '../../assets/images/icons/delete.svg';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteFormEntries, getFormEntries } from '../../utils/apiConstants';
import { handleSelectChange, pagintaionPageCount } from '../../utils/constants';
import Pagination from '../../components/common/pagination';
import Loader from '../../components/common/loader';
import { useDebounce } from '../../utils/useDebounce';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';
import { Fade, Modal } from '@mui/material';
import CustomTableCell from '../../components/common/CustomTableCell';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const theme = createTheme();

const FormEntries = () => {
    const [loader, setLoader] = useState(true);
    const [formEntries, setFormEntries] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [selectedSlugs, setSelectedSlugs] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [formCounts, setFormCounts] = useState({});
    const [value, setValue] = useState(0);
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const trashModalOpen = () => setOpen(true);
    const trashModalClose = () => setOpen(false);

    const handleChange = newValue => {
        setValue(newValue);
        setSelectedPage(1);
    };

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedSlugs([]);
        } else {
            const allSlugs = formEntries?.map(item => item?.id);
            setSelectedSlugs(allSlugs);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleRowCheckboxChange = id => {
        if (selectedSlugs.includes(id)) {
            setSelectedSlugs(selectedSlugs.filter(item => item !== id));
        } else {
            setSelectedSlugs([...selectedSlugs, id]);
        }
    };

    const getForm = value => {
        switch (value) {
            case 1:
                return 'request_listing';
            case 2:
                return 'backlinks';
            case 3:
                return 'advertise';
            case 4:
                return 'profile_claim';
            case 5:
                return 'profile_contact';
            case 6:
                return 'profile_ads';
            case 7:
                return 'affiliate';

            default:
                return 'contact_us';
        }
    };

    const getTabColumn = () => {
        switch (value) {
            case 1:
                return 'request_listing_owner';
            case 2:
                return 'backlink_name';
            case 3:
                return 'advertise_name';
            case 4:
                return 'profileclaim_name';
            case 5:
                return 'profilecontact_name';
            case 6:
                return 'profileads_name';
            case 7:
                return 'affiliate_name';

            default:
                return 'contact_name';
        }
    };

    const deleteFormEntriesById = async () => {
        setLoader(true);
        try {
            if (selectedSlugs) {
                const responses = await deleteFormEntries(
                    getForm(value),
                    selectedSlugs.join(',')
                );
                if (responses?.data?.status == 200) {
                    setSelectedPage(1);
                    toast.success(responses?.data?.message);
                    setOpen(false);
                    setHeaderChecked(false);
                    setSelectedSlugs([]);
                    getFormEntryList();
                }
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    error?.message
            );
        }
        setLoader(false);
    };

    const getFormEntryList = async (
        newSortOrder = sortOrder,
        column = sortBy
    ) => {
        setLoader(true);
        try {
            const response = await getFormEntries(
                getForm(value),
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                encodeURIComponent(searchValue),
                sortBy || column
                    ? (newSortOrder || sortOrder) === 'asc'
                        ? getTabColumn(value) || sortBy
                        : `-` + getTabColumn(value) || sortBy
                    : sortBy
            );
            if (response?.status == 200) {
                setFormEntries(response?.data?.results?.data || []);
                setTotalCount(response?.data?.count || 0);
                setFormCounts(response?.data?.results?.form_counts);
                setTotalPage(
                    Math.ceil(response?.data?.count / showPerPage) || 0
                );
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.detail ||
                    error?.response?.data?.message ||
                    error?.response?.data?.error
            );
        }
        setLoader(false);
    };

    const handleSort = column => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getFormEntryList(newSortOrder, column);
    };

    const fetchData = pageSize => {
        setSelectedPage(1);
        setShowPerPage(pageSize);
    };

    useEffect(() => {
        getFormEntryList();
    }, [debouncedSearchTerm, showPerPage, selectedPage, value]);

    const [openRow, setOpenRow] = useState(null);

    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    const getSortIcon = column => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward size={20} />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward size={20} />;
        } else {
            return <SwapVert size={20} />;
        }
    };

    const formOption = [
        { value: 0, label: 'Contact Us' },
        { value: 1, label: 'Request Listing' },
        { value: 2, label: 'Backlinks' },
        { value: 3, label: 'Advertise' },
        { value: 4, label: 'Profile Claim' },
        { value: 5, label: 'Profile Contact' },
        { value: 6, label: 'Profile Ads' },
        { value: 7, label: 'Affiliate Program' },
    ];

    const customStyles = {
        control: provided => ({
            ...provided,
            width: '220px',
        }),
        menu: provided => ({
            ...provided,
            zIndex: 9999,
        }),
    };

    return (
        <>
            <Box className="content-header companies-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        Form Entries
                    </Typography>
                    <Select
                        defaultValue={formOption[0]}
                        styles={customStyles}
                        menuPlacement="auto"
                        className={'select-ui'}
                        classNamePrefix="select"
                        onChange={selectedOption =>
                            handleChange(selectedOption?.value)
                        }
                        options={formOption}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                    />
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search Form Entries"
                            onChange={e => {
                                setSearchValue(e.target.value);
                                setSelectedPage(1);
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Box className="tab-ui-wrapper">
                    {/* <Tabs
                        className="tab-button-wrapper"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label={`Contact Us (${
                                formCounts?.contact_us || 0
                            })`}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={`Request Listing (${
                                formCounts?.request_listing || 0
                            })`}
                            {...a11yProps(1)}
                        />
                        <Tab
                            label={`Backlinks (${formCounts?.backlinks || 0})`}
                            {...a11yProps(2)}
                        />
                        <Tab
                            label={`Advertise (${formCounts?.advertise || 0})`}
                            {...a11yProps(3)}
                        />
                        <Tab
                            label={`Profile Claim (${
                                formCounts?.profile_claim || 0
                            })`}
                            {...a11yProps(4)}
                        />
                        <Tab
                            label={`Profile Contact (${
                                formCounts?.profile_contact || 0
                            })`}
                            {...a11yProps(5)}
                        />
                        <Tab
                            label={`Profile Ads Entries (${
                                formCounts?.profile_ads || 0
                            })`}
                            {...a11yProps(6)}
                        />
                        <Tab
                            label={`Affiliate Program (${
                                formCounts?.affiliate_program || 0
                            })`}
                            {...a11yProps(7)}
                        />
                    </Tabs> */}
                    <CustomTabPanel value={value} index={value}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="plan-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            <TableCell className="table-checkbox-width">
                                                <Checkbox
                                                    className="checkbox-ui"
                                                    checked={headerChecked}
                                                    onChange={
                                                        handleHeaderCheckboxChange
                                                    }
                                                />
                                            </TableCell>
                                            {value === 6 && (
                                                <>
                                                    <TableCell>Ad ID</TableCell>
                                                    <TableCell>
                                                        Profile
                                                    </TableCell>
                                                </>
                                            )}
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('name');
                                                    handleSort('name');
                                                }}
                                            >
                                                Name {getSortIcon('name')}
                                            </TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Phone</TableCell>
                                            {(value === 0 || value === 6) && (
                                                <TableCell>Note</TableCell>
                                            )}
                                            {(value === 1 || value === 3) && (
                                                <TableCell>Business</TableCell>
                                            )}
                                            {value === 2 && (
                                                <>
                                                    <TableCell>
                                                        Package
                                                    </TableCell>
                                                    <TableCell>
                                                        Detail
                                                    </TableCell>
                                                </>
                                            )}
                                            {value === 3 && (
                                                <TableCell>Message</TableCell>
                                            )}
                                            {(value === 4 || value === 5) && (
                                                <TableCell>Profile</TableCell>
                                            )}
                                            {value === 5 && (
                                                <TableCell>Message</TableCell>
                                            )}
                                            {value === 7 && (
                                                <TableCell>Questions</TableCell>
                                            )}
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={
                                                        value === 0 ||
                                                        value === 1 ||
                                                        value === 4
                                                            ? 6
                                                            : 7
                                                    }
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : formEntries?.length > 0 ? (
                                            formEntries?.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        onClick={e => {
                                                            if (
                                                                isSmallScreen &&
                                                                e.target
                                                                    .type !==
                                                                    'checkbox'
                                                            ) {
                                                                handleRowClick(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                        className={
                                                            openRow === index
                                                                ? 'open'
                                                                : ''
                                                        }
                                                    >
                                                        <TableCell className="table-checkbox-width">
                                                            <Checkbox
                                                                className="checkbox-ui"
                                                                checked={selectedSlugs.includes(
                                                                    row?.id
                                                                )}
                                                                onChange={() =>
                                                                    handleRowCheckboxChange(
                                                                        row?.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        {value === 6 && (
                                                            <>
                                                                <TableCell>
                                                                    <Box className="show-text-arrow-content">
                                                                        {
                                                                            row
                                                                                ?.form_data
                                                                                ?.profileads_profile_id
                                                                        }
                                                                        {openRow ===
                                                                        index ? (
                                                                            <KeyboardArrowUpIcon />
                                                                        ) : (
                                                                            <KeyboardArrowDownIcon />
                                                                        )}
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell
                                                                    className="hide-td"
                                                                    sx={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                >
                                                                    {row
                                                                        ?.form_data
                                                                        ?.profileads_profile_url && (
                                                                        <Link
                                                                            to={
                                                                                row
                                                                                    ?.form_data
                                                                                    ?.profileads_profile_url
                                                                            }
                                                                            className="MuiLink-root fw-600 cursor-pointer"
                                                                            target="_blank"
                                                                        >
                                                                            {
                                                                                row
                                                                                    ?.form_data
                                                                                    ?.profileads_profile_url
                                                                            }
                                                                        </Link>
                                                                    )}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        <TableCell
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                        >
                                                            <Box className="show-text-arrow-content">
                                                                {row?.form_data
                                                                    ?.contact_name ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.request_listing_owner ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.backlink_name ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.advertise_name ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.profileclaim_name ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.profilecontact_name ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.profileads_name ||
                                                                    row
                                                                        ?.form_data
                                                                        ?.affiliate_name ||
                                                                    '-'}
                                                                {openRow ===
                                                                index ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </Box>
                                                        </TableCell>

                                                        <TableCell className="hide-td">
                                                            {row?.form_data
                                                                ?.contact_email ||
                                                                row?.form_data
                                                                    ?.request_listing_submitter_email ||
                                                                row?.form_data
                                                                    ?.backlink_email ||
                                                                row?.form_data
                                                                    ?.advertise_email ||
                                                                row?.form_data
                                                                    ?.profileclaim_email ||
                                                                row?.form_data
                                                                    ?.profilecontact_email ||
                                                                row?.form_data
                                                                    ?.profileads_email ||
                                                                row?.form_data
                                                                    ?.affiliate_email ||
                                                                '-'}
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                whiteSpace:
                                                                    'nowrap',
                                                            }}
                                                            className="hide-td"
                                                        >
                                                            {row?.form_data
                                                                ?.contact_phone ||
                                                                row?.form_data
                                                                    ?.request_listing_phone ||
                                                                row?.form_data
                                                                    ?.request_listing_submitter_phone ||
                                                                row?.form_data
                                                                    ?.backlink_phone ||
                                                                row?.form_data
                                                                    ?.advertise_phone ||
                                                                row?.form_data
                                                                    ?.profileclaim_phone ||
                                                                row?.form_data
                                                                    ?.profilecontact_phone ||
                                                                row?.form_data
                                                                    ?.profileads_phone ||
                                                                row?.form_data
                                                                    ?.affiliate_phone ||
                                                                '-'}
                                                        </TableCell>
                                                        {(value === 0 ||
                                                            value === 6) && (
                                                            <>
                                                                <TableCell className="hide-td">
                                                                    <CustomTableCell
                                                                        text={
                                                                            row
                                                                                ?.form_data
                                                                                ?.contact_note ||
                                                                            row
                                                                                ?.form_data
                                                                                ?.profileads_note ||
                                                                            '-'
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        {(value === 1 ||
                                                            value === 3) && (
                                                            <>
                                                                <TableCell
                                                                    sx={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                    className="hide-td"
                                                                >
                                                                    {row
                                                                        ?.form_data
                                                                        ?.request_listing_business_name ||
                                                                        row
                                                                            ?.form_data
                                                                            ?.advertise_business ||
                                                                        '-'}
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        {value === 2 && (
                                                            <>
                                                                <TableCell
                                                                    sx={{
                                                                        whiteSpace:
                                                                            'nowrap',
                                                                    }}
                                                                    className="hide-td"
                                                                >
                                                                    {row
                                                                        ?.form_data
                                                                        ?.backlink_package ||
                                                                        '-'}
                                                                </TableCell>
                                                                <TableCell className="hide-td">
                                                                    <CustomTableCell
                                                                        text={
                                                                            row
                                                                                ?.form_data
                                                                                ?.backlink_details ||
                                                                            '-'
                                                                        }
                                                                    />
                                                                </TableCell>
                                                            </>
                                                        )}
                                                        {value === 3 && (
                                                            <TableCell className="hide-td">
                                                                <CustomTableCell
                                                                    text={
                                                                        row
                                                                            ?.form_data
                                                                            ?.advertise_message ||
                                                                        '-'
                                                                    }
                                                                />
                                                            </TableCell>
                                                        )}
                                                        {(value === 4 ||
                                                            value === 5) && (
                                                            <TableCell
                                                                sx={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                }}
                                                                className="hide-td"
                                                            >
                                                                {
                                                                    row
                                                                        ?.form_data
                                                                        ?.profileclaim_profile_business_name
                                                                }
                                                                <br />
                                                                <Link
                                                                    className="MuiLink-root fw-600 cursor-pointer"
                                                                    to={`${
                                                                        row
                                                                            ?.form_data
                                                                            ?.profileclaim_profile_url ||
                                                                        row
                                                                            ?.form_data
                                                                            ?.profilecontact_profile_url
                                                                    }`}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {row
                                                                        ?.form_data
                                                                        ?.profileclaim_profile_url ||
                                                                        row
                                                                            ?.form_data
                                                                            ?.profilecontact_profile_url}
                                                                </Link>
                                                            </TableCell>
                                                        )}
                                                        {value === 5 && (
                                                            <TableCell className="hide-td">
                                                                <CustomTableCell
                                                                    text={
                                                                        row
                                                                            ?.form_data
                                                                            ?.profilecontact_message ||
                                                                        '-'
                                                                    }
                                                                />
                                                            </TableCell>
                                                        )}
                                                        {value === 7 && (
                                                            <TableCell className="hide-td">
                                                                {row?.form_data
                                                                    ?.affiliate_questions ||
                                                                    '-'}
                                                            </TableCell>
                                                        )}
                                                        <TableCell className="hide-td">
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                gap={3}
                                                                className="action-wrapper"
                                                            >
                                                                <Button
                                                                    className="btn edit-btn"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/form-entries/${getForm(
                                                                                value
                                                                            )}/${
                                                                                row?.id
                                                                            }`
                                                                        )
                                                                    }
                                                                >
                                                                    <VisibilityIcon />
                                                                </Button>
                                                                <Button
                                                                    className="btn delete-btn"
                                                                    onClick={() => {
                                                                        setSelectedSlugs(
                                                                            [
                                                                                ...selectedSlugs,
                                                                                row?.id,
                                                                            ]
                                                                        );
                                                                        trashModalOpen();
                                                                    }}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    {openRow === index && (
                                                        <TableRow className="accordion-content">
                                                            <TableCell
                                                                colSpan={
                                                                    value ===
                                                                        0 ||
                                                                    value ===
                                                                        1 ||
                                                                    value === 4
                                                                        ? 5
                                                                        : 6
                                                                }
                                                            >
                                                                <Box className="accordion-box">
                                                                    <Stack
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        {value ===
                                                                            6 && (
                                                                            <>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    component="p"
                                                                                    className="MuiLink-root fw-600"
                                                                                >
                                                                                    Ad
                                                                                    ID:
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="p"
                                                                                    >
                                                                                        {
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.profileads_profile_id
                                                                                        }
                                                                                        {openRow ===
                                                                                        index ? (
                                                                                            <KeyboardArrowUpIcon />
                                                                                        ) : (
                                                                                            <KeyboardArrowDownIcon />
                                                                                        )}
                                                                                    </Typography>
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    component="p"
                                                                                    className="MuiLink-root fw-600"
                                                                                >
                                                                                    Profile:
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="p"
                                                                                    >
                                                                                        <Link
                                                                                            className="MuiLink-root fw-600 cursor-pointer"
                                                                                            to={
                                                                                                row
                                                                                                    ?.form_data
                                                                                                    ?.profileads_profile_url
                                                                                            }
                                                                                            target="_blank"
                                                                                        >
                                                                                            {
                                                                                                row
                                                                                                    ?.form_data
                                                                                                    ?.profileads_profile_url
                                                                                            }
                                                                                        </Link>
                                                                                    </Typography>
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                        {value !==
                                                                            6 && (
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="MuiLink-root fw-600"
                                                                            >
                                                                                Name:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {row
                                                                                        ?.form_data
                                                                                        ?.contact_name ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.request_listing_owner ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.backlink_name ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.advertise_name ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.profileclaim_name ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.profilecontact_name ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.profileads_name ||
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.affiliate_name ||
                                                                                        '-'}
                                                                                </Typography>
                                                                            </Typography>
                                                                        )}

                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Email:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {row
                                                                                    ?.form_data
                                                                                    ?.contact_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.request_listing_submitter_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.backlink_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.advertise_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.profileclaim_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.profilecontact_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.profileads_email ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.affiliate_email ||
                                                                                    '-'}
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Phone:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {row
                                                                                    ?.form_data
                                                                                    ?.contact_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.request_listing_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.request_listing_submitter_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.backlink_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.advertise_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.profileclaim_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.profilecontact_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.profileads_phone ||
                                                                                    row
                                                                                        ?.form_data
                                                                                        ?.affiliate_phone ||
                                                                                    '-'}
                                                                            </Typography>
                                                                        </Typography>
                                                                        {(value ===
                                                                            0 ||
                                                                            value ===
                                                                                6) && (
                                                                            <>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    component="p"
                                                                                    className="MuiLink-root fw-600"
                                                                                >
                                                                                    Note:
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="p"
                                                                                    >
                                                                                        <CustomTableCell
                                                                                            text={
                                                                                                row
                                                                                                    ?.form_data
                                                                                                    ?.contact_note ||
                                                                                                row
                                                                                                    ?.form_data
                                                                                                    ?.request_listing_business_name ||
                                                                                                row
                                                                                                    ?.form_data
                                                                                                    ?.profileads_note ||
                                                                                                '-'
                                                                                            }
                                                                                        />
                                                                                    </Typography>
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                        {(value ===
                                                                            1 ||
                                                                            value ===
                                                                                3) && (
                                                                            <>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    component="p"
                                                                                    className="fw-600"
                                                                                >
                                                                                    Business:
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="p"
                                                                                    >
                                                                                        {row
                                                                                            ?.form_data
                                                                                            ?.request_listing_business_name ||
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.advertise_business ||
                                                                                            '-'}
                                                                                    </Typography>
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                        {value ===
                                                                            2 && (
                                                                            <>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    component="p"
                                                                                    className="fw-600"
                                                                                >
                                                                                    Package:
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="p"
                                                                                    >
                                                                                        {row
                                                                                            ?.form_data
                                                                                            ?.backlink_package ||
                                                                                            '-'}
                                                                                    </Typography>
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body1"
                                                                                    component="p"
                                                                                    className="fw-600"
                                                                                >
                                                                                    Detail:
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        component="p"
                                                                                    >
                                                                                        <CustomTableCell
                                                                                            text={
                                                                                                row
                                                                                                    ?.form_data
                                                                                                    ?.backlink_details ||
                                                                                                '-'
                                                                                            }
                                                                                        />
                                                                                    </Typography>
                                                                                </Typography>
                                                                            </>
                                                                        )}
                                                                        {value ===
                                                                            3 && (
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Message:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    <CustomTableCell
                                                                                        text={
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.advertise_message ||
                                                                                            '-'
                                                                                        }
                                                                                    />
                                                                                </Typography>
                                                                            </Typography>
                                                                        )}
                                                                        {(value ===
                                                                            4 ||
                                                                            value ===
                                                                                5) && (
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Profile:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {
                                                                                        row
                                                                                            ?.form_data
                                                                                            ?.profileclaim_profile_business_name
                                                                                    }
                                                                                    <br />
                                                                                    <Link
                                                                                        className="MuiLink-root fw-600 cursor-pointer"
                                                                                        href={`${
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.profileclaim_profile_url ||
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.profilecontact_profile_url
                                                                                        }`}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {row
                                                                                            ?.form_data
                                                                                            ?.profileclaim_profile_url ||
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.profilecontact_profile_url}
                                                                                    </Link>
                                                                                </Typography>
                                                                            </Typography>
                                                                        )}
                                                                        {value ===
                                                                            5 && (
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Message:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    <CustomTableCell
                                                                                        text={
                                                                                            row
                                                                                                ?.form_data
                                                                                                ?.advertise_message ||
                                                                                            '-'
                                                                                        }
                                                                                    />
                                                                                    {row
                                                                                        ?.form_data
                                                                                        ?.profilecontact_message ||
                                                                                        '-'}
                                                                                </Typography>
                                                                            </Typography>
                                                                        )}
                                                                        {value ===
                                                                            7 && (
                                                                            <Typography
                                                                                variant="body1"
                                                                                component="p"
                                                                                className="fw-600"
                                                                            >
                                                                                Questions:
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    component="p"
                                                                                >
                                                                                    {row
                                                                                        ?.form_data
                                                                                        ?.affiliate_questions ||
                                                                                        '-'}
                                                                                </Typography>
                                                                            </Typography>
                                                                        )}
                                                                        <Stack
                                                                            direction={
                                                                                'row'
                                                                            }
                                                                            gap={
                                                                                3
                                                                            }
                                                                            className="action-wrapper"
                                                                        >
                                                                            <Button
                                                                                className="btn edit-btn"
                                                                                onClick={() =>
                                                                                    navigate(
                                                                                        `/form-entries/${getForm(
                                                                                            value
                                                                                        )}/${
                                                                                            row?.id
                                                                                        }`
                                                                                    )
                                                                                }
                                                                            >
                                                                                <VisibilityIcon />
                                                                            </Button>
                                                                            <Button
                                                                                className="btn delete-btn"
                                                                                onClick={() => {
                                                                                    setSelectedSlugs(
                                                                                        [
                                                                                            ...selectedSlugs,
                                                                                            row?.id,
                                                                                        ]
                                                                                    );
                                                                                    trashModalOpen();
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon />
                                                                            </Button>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={
                                                        value === 0 ||
                                                        value === 1 ||
                                                        value === 4
                                                            ? 6
                                                            : 7
                                                    }
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </CustomTabPanel>
                </Box>
            </Box>

            <Modal
                open={open}
                onClose={trashModalClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-ui">
                        <Box className="modal-body">
                            <Box className="confirmation-message-wrapper">
                                <Box className="image-wrapper">
                                    <img src={DeleteTrash} />
                                </Box>
                                <Box className="confirm-content-wrapper">
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        className="confirm-title"
                                    >
                                        Are you sure want to delete this form
                                        entry?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        className="confirm-para"
                                    >
                                        This action is permanent and cannot be
                                        undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="modal-footer-wrapper">
                                <Button
                                    className="btn red-btn"
                                    onClick={() => deleteFormEntriesById()}
                                >
                                    Delete
                                </Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={trashModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default FormEntries;
