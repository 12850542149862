export const pagintaionPageCount = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
];

export const handleSelectChange = (
    selectedOption,
    setShowPerPage,
    fetchData
) => {
    setShowPerPage(selectedOption.value);
    fetchData(selectedOption.value);
};

export const getFormattedDateTime = (date = new Date()) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const pad = num => num.toString().padStart(2, '0');

    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = pad(date.getMinutes());

    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedDate = `${month}/${day}/${year} ${pad(hours)}:${minutes} ${ampm}`;
    return formattedDate;
};


export const transformProfileFormData = formData => {
    return {
        title: formData.title,
        slug: formData.slug,
        category_name: formData.category_name,
        content: formData.content,
        meta_title: formData.meta_title,
        meta_description: formData.meta_description,
        meta_keywords: formData.meta_keywords,
        add_content: {
            add_company: formData.add_company,
            add_keywords: formData.add_keywords,
            add_tag_line: formData.add_tag_line,
            add_website: formData.add_website,
            add_phone: formData.add_phone,
            add_contact_email: formData.add_contact_email,
            add_owner: formData.add_owner,
            add_submitter_email: formData.add_submitter_email,
            add_submitter_phone: formData.add_submitter_phone,
            add_keyword_1: formData.add_keyword_1,
            add_keyword_2: formData.add_keyword_2,
            add_keyword_3: formData.add_keyword_3,
            add_overview: formData.add_overview,
        },
        address: {
            addr_full_address: formData.addr_full_address,
            addr_address_1: formData.addr_address_1,
            addr_address_2: formData.addr_address_2,
            addr_address_3: formData.addr_address_3,
            addr_city: formData.addr_city,
            addr_state: formData.addr_state,
            addr_zip: formData.addr_zip,
            addr_county: formData.addr_county,
            addr_latitude: formData.addr_latitude,
            addr_longitude: formData.addr_longitude,
            addr_other_state: formData.addr_other_state,
            addr_country: formData.addr_country,
        },
        tab_hours: {
            tab_hours_monday: formData.tab_hours_monday || "Closed",
            tab_hours_tuesday: formData.tab_hours_tuesday|| "Closed",
            tab_hours_wednesday: formData.tab_hours_wednesday|| "Closed",
            tab_hours_thursday: formData.tab_hours_thursday|| "Closed",
            tab_hours_friday: formData.tab_hours_friday|| "Closed",
            tab_hours_saturday: formData.tab_hours_saturday|| "Closed",
            tab_hours_sunday: formData.tab_hours_sunday|| "Closed",
            tab_buynow_button_text: formData.tab_buynow_button_text,
            tab_buynow_outgoing_url: formData.tab_buynow_outgoing_url,
            tab_buynow_background_color: formData.tab_buynow_background_color,
            tab_buynow_text_color: formData.tab_buynow_text_color,
            tab_buynow_triggered_event: formData.tab_buynow_triggered_event,
        },
        info: {
            icon_link_url: formData.icon_link_url,
            icon_link_follow: formData.icon_link_follow,
            icon_link_target: formData.icon_link_target,
            icon_link_image_alt: formData.icon_link_image_alt,
            shop_url: formData.shop_url,
            disable_adsense: formData.disable_adsense,
            show_contact_form: formData.show_contact_form,
            claim_status: formData.claim_status,
            post_status: formData.post_status || 'publish',
            comment_status: formData.comment_status || '',
            wp_category_link: false,
            wp_tag_link: false,
            wp_reviews: formData.wp_reviews || false,
        },
    };
};

export const transformProfileEditFormData = formData => {
    return {
        title: formData.title,
        slug: formData.slug,
        author: formData.author,
        category_name: formData.category_name,
        meta_title: formData.meta_title,
        meta_description: formData.meta_description,
        meta_keywords: formData.meta_keywords,
        add_content: {
            add_keywords: formData.add_keywords,
        },
        address: {
            addr_state: formData.addr_state,
        },
        info: {
            disable_adsense: formData.disable_adsense,
            show_contact_form: formData.show_contact_form,
            claim_status: formData.claim_status,
            comment_status: formData.comment_status,
            wp_reviews: formData.wp_reviews,
        },
    };
};

export const editorConfig = {
    modules: {
      toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, 
         { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    },
    formats: [
      'header', 'font', 'size',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image', 'video',
    ],
  };
  

export const getTabHoursValue = (day) => {
    if (day === 'Closed') {
        return false;
    }else{
        return day;
    }
}

export const formatTime = (hours, minutes) => {
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${period}`;
}
