import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/layout';
import { toast } from 'react-toastify';
import { getUserDetail } from '../utils/apiConstants';
import Loader from '../components/common/loader';
import { Box } from '@mui/material';

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);

     const getUserDetails = async() =>{
        try {
            const response = await getUserDetail();
            if (response?.status == 200) {
               localStorage.setItem('user', JSON.stringify(response?.data?.data));
               setUser(response?.data?.data);
               setIsLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data?.error);
        }

    }
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        if (token) {
            localStorage.setItem('token', token);
            getUserDetails();
            navigate(window.location.pathname, { replace: true });
            setIsLoading(false);
        } else {
            const storedToken = localStorage.getItem('token');
            if (!storedToken) {
                window.location.href = `${process.env.REACT_APP_FRONTEND_SITE_URL}/login`;
            }else {
                setUser(localStorage.getItem('user'));
                setIsLoading(false);
            }
        }
    }, [navigate]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userRole = user?.role;
        const currentPath = window.location.pathname.slice(1);
        const restrictedPagesForUser = ['add-profile','edit-profile','plan','add-plan','edit-plan', 'state', 'counties', 'categories', 'companies', 'users','add-user'];
        const dynamicRestrictedPaths = ['edit-plan', 'plan','edit-user','view-user']; 
        const restrictedUserPages = ['users','add-user'];
        const dynamicRestrictedUserPaths = ['edit-user','view-user']; 
        if (userRole === 'user' && (restrictedPagesForUser.includes(currentPath)  ||
        dynamicRestrictedPaths.some(path => currentPath.startsWith(path)))) {
            toast.error('You do not have permission to access this page.');
            navigate('/'); 
        }
        if (userRole === 'editor' || userRole === 'author'){
            if(restrictedUserPages.includes(currentPath)  || dynamicRestrictedUserPaths.some(path => currentPath.startsWith(path))){
                toast.error('You do not have permission to access this page.');
                navigate('/'); 
            }
        }
    }, [isLoading,navigate]);

    return (
        isLoading || !user ? (
            <Box className="h-100vh">
                <Loader />  
            </Box>
        ) : (
            <Layout>
                {children}
            </Layout>
        )
    );
};

export default ProtectedRoute;
