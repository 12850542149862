import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, Button, Stack, CircularProgress } from '@mui/material';
import Select from 'react-select';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addPlan, editPlan, getPlanById } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/loader';

const typeOption = [
    { value: 'premium', label: 'Premium' },
    { value: 'seo', label: 'SEO' },
];
const statusOption = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
];

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').matches(
        /^(?![-\s]*$).+/,
        "Title cannot be empty or only contain spaces and hyphens"
      ),
    price: Yup.number()
    .required('Price is required')
    .positive('Price must be a positive number')
    .test(
      'max-digits-before-decimal',
      'Price can have at most 8 digits before the decimal point and 2 digits after it',
      (value) => {
        // Check if the value is valid
        if (value) {
          // Convert to string to check digits
          const stringValue = value.toString();
          const [integerPart, decimalPart] = stringValue.split('.');

          // Ensure integer part is at most 8 digits and decimal part (if present) is at most 2 digits
          return integerPart.length <= 8 && (!decimalPart || decimalPart.length <= 2);
        }
        return true; // If value is empty or invalid, Yup's required/positive rules will handle it
      }
    ),
    stripe_price_id: Yup.string().required('Product Pricing ID is required'),
    type: Yup.string().required('Type is required'),
    is_active: Yup.string().required('Status is required'),
    features: Yup.array().of(Yup.string().required('Feature is required')),
});

const AddEditPlan = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [planDetail, setPlanDetail] = useState([]);

        const getPlanDetailsById = async () => {
        setIsDetailLoading(true);
        try {
            const response = await getPlanById(id);
            if (response?.status === 200) {
                setPlanDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
        setIsDetailLoading(false);
    };

    const savePlanDetail = async values => {
        setLoader(true);
        values.features = values?.features?.join(', ');        
        try {
            let responses;
            if (id) {
                responses = await editPlan(id, values);
            } else {
                responses = await addPlan(values);
            }
            if (
                responses?.data?.status == 201 ||
                responses?.data?.status == 200
            ) {
                toast.success(responses?.data?.message);
            }
            navigate('/plans');
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
        setLoader(false);
    };

    useEffect(() => {
        if (id) {
            getPlanDetailsById();
        }
    }, [id]);

    const initialValues = {
        title: planDetail?.title ? planDetail?.title : '',
        price: planDetail?.price ? planDetail?.price : '',
        stripe_price_id: planDetail?.stripe_price_id ? planDetail?.stripe_price_id : '',
        type: planDetail?.type ? planDetail?.type : typeOption[0]?.value,
        is_active: planDetail?.is_active
            ? planDetail?.is_active : !id ? statusOption[0]?.value : '',
        features: planDetail?.features ?  planDetail?.features?.startsWith("[") &&
            planDetail?.features?.endsWith("]") ? planDetail?.features
            ?.slice(1, -1)
            ?.replace(/['"]/g, "")
            ?.replace(/\\\//g, "/")
            ?.split(",")
            ?.map((feature) => feature?.trim()) : planDetail?.features
            ?.split(",")
            ?.map((feature) => feature?.trim()) : ['']
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                savePlanDetail(values);
                resetForm();
                setSubmitting(false);
            }}
            enableReinitialize
        >
            {({ values, setFieldValue, errors }) => (
                <Form>
                    <Box className="content-header profile-module-wrapper">
                        <Box className="content-header-left">
                            <Typography variant="h2" component="h2">
                                {id ? 'Edit' : 'Add New'} Plan
                            </Typography>
                        </Box>
                    </Box>
                    {isDetailLoading ? (
                        <Box className="h-70vh">
                            <Loader />
                        </Box>
                    ) : (
                        <Box className="content-layout mt-22 form-field-wrapper">
                            <Grid container spacing={3}>
                                <Grid item lg={8} md={7} sm={12} xs={12}>
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Plan Details
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Title
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            className="astrick"
                                                        >
                                                            {' '}
                                                            *
                                                        </Typography>
                                                    </Typography>
                                                    <Field
                                                        name="title"
                                                        as={TextField}
                                                        className="input-ui"
                                                        placeholder="Enter Title"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage
                                                        name="title"
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Price
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            className="astrick"
                                                        >
                                                            {' '}
                                                            *
                                                        </Typography>
                                                    </Typography>
                                                    <Box className="field-count-wrapper">
                                                        <Box className="field-count">
                                                            $
                                                        </Box>
                                                        <Field
                                                            name="price"
                                                            as={TextField}
                                                            className="input-ui"
                                                            placeholder="Enter Price"
                                                            fullWidth
                                                        />
                                                    </Box>
                                                    <ErrorMessage
                                                        name="price"
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Product Pricing ID
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            className="astrick"
                                                        >
                                                            {' '}
                                                            *
                                                        </Typography>
                                                    </Typography>
                                                    <Field
                                                        name="stripe_price_id"
                                                        as={TextField}
                                                        className="input-ui"
                                                        placeholder="Enter Product Pricing ID"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage
                                                        name="stripe_price_id"
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={5} sm={12} xs={12}>
                                    <Box className="card-wrapper h-100">
                                        <Stack className="p-16" spacing={3}>
                                            <Box className="field-wrapper">
                                                <Typography
                                                    variant="body1"
                                                    component="label"
                                                >
                                                    Type
                                                </Typography>
                                                <Select
                                                    menuPlacement="auto"
                                                    defaultValue={typeOption[0]}
                                                    value={typeOption?.filter(
                                                        option =>
                                                            values?.type?.includes(
                                                                option?.value
                                                            )
                                                    )}
                                                    className={'select-ui'}
                                                    classNamePrefix="select"
                                                    options={typeOption}
                                                    placeholder="Select Type"
                                                    onChange={option =>
                                                        setFieldValue(
                                                            'type',
                                                            option?.value
                                                        )
                                                    }
                                                />
                                                <ErrorMessage
                                                    name="type"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Box>
                                            <Box className="field-wrapper">
                                                <Typography
                                                    variant="body1"
                                                    component="label"
                                                >
                                                    Status
                                                </Typography>
                                                <Select
                                                    menuPlacement="auto"
                                                    value={statusOption?.filter(
                                                        option =>
                                                            values?.is_active == 
                                                                option?.value
                                                    )}
                                                    className={'select-ui'}
                                                    classNamePrefix="select"
                                                    options={statusOption}
                                                    placeholder="Select Status"
                                                    onChange={option =>
                                                        setFieldValue(
                                                            'is_active',
                                                            option?.value
                                                        )
                                                    }
                                                />
                                                <ErrorMessage
                                                    name="is_active"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item lg={8} md={8} sm={12} xs={12}>
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    Features
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        className="astrick"
                                                    >
                                                        {' '}
                                                        *
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="add-features-wrapper">
                                                    <FieldArray name="features">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {Array.isArray(values?.features) && values?.features?.map(
                                                                    (
                                                                        feature,
                                                                        index
                                                                    ) => (
                                                                        <>
                                                                            <Box
                                                                                className="field-wrapper"
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <Field
                                                                                    name={`features[${index}]`}
                                                                                    as={
                                                                                        TextField
                                                                                    }
                                                                                    className="input-ui"
                                                                                    placeholder={`Feature ${
                                                                                        index +
                                                                                        1
                                                                                    }`}
                                                                                    fullWidth
                                                                                />
                                                                                <Stack
                                                                                    direction={
                                                                                        'row'
                                                                                    }
                                                                                    gap={
                                                                                        3
                                                                                    }
                                                                                    className="action-wrapper"
                                                                                >
                                                                                    {index ===
                                                                                        values?.features?.length -
                                                                                            1 && (
                                                                                        <Button
                                                                                            className="btn add-btn"
                                                                                            onClick={() =>
                                                                                                push(
                                                                                                    ''
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <AddCircleOutlineRoundedIcon />
                                                                                        </Button>
                                                                                    )}
                                                                                    {values?.features?.length >
                                                                                        1 && (
                                                                                        <Button
                                                                                            className="btn delete-btn"
                                                                                            onClick={() =>
                                                                                                remove(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <DeleteForeverIcon />
                                                                                        </Button>
                                                                                    )}
                                                                                </Stack>
                                                                            </Box>
                                                                            <ErrorMessage
                                                                                name={`features[${index}]`}
                                                                                component="div"
                                                                                className="error-message"
                                                                            />
                                                                        </>
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="footer-wrapper">
                                <Box className="btn-wrapper">
                                    <Button
                                        className="btn primary-btn"
                                        type="submit"
                                        disabled={loader}
                                        endIcon={loader && <CircularProgress sx={{
                                            color: "var(--white-color)",
                                          }} size={16} />}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="btn secondary-btn"
                                        onClick={() => navigate('/plans')}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default AddEditPlan;
