import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

// Styled component for multiline ellipsis text
const EllipsisText = styled('div')`
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust as needed */
`;

const InfoButton = styled(IconButton)`
  margin-left: 8px;
`;

const PopoverContent = styled('div')`
  padding: 16px;
  max-width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
`;

const CustomTableCell = ({ text }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EllipsisText dangerouslySetInnerHTML={{ __html: text }}></EllipsisText>
        {text && (
          <>
          {text && text != '-' && (
            <InfoButton onClick={handleClick}>
              <InfoIcon />
            </InfoButton>
          )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <PopoverContent>
                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text }} sx={{whiteSpace:'pre-wrap'}}></Typography>
              </PopoverContent>
            </Popover>
          </>
        )}
      </div>
  );
};

export default CustomTableCell;
