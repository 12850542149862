import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrderById } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/loader';
import { Chip } from '@mui/material';
import { getFormattedDateTime } from '../../utils/constants';
import CancelSubscription from './cancelSubscription';

const ViewOrder = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isDetailLoading, setIsDetailLoading] = useState(true);
    const [orderDetail, setOrderDetail] = useState([]);
    const [open, setOpen] = useState(false);
    const userDetail = localStorage.getItem('user');
    const parsedUser = userDetail ? JSON.parse(userDetail) : null;
    const [user, setUser] = useState(parsedUser);

    const handleClose = () => setOpen(false);

    const getOrderDetailsById = async () => {
        setIsDetailLoading(true);
        try {
            const response = await getOrderById(id);
            if (response?.status === 200) {
                setOrderDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
        setIsDetailLoading(false);
    };

    useEffect(() => {
        if (id) {
            getOrderDetailsById();
        }
    }, [id]);

    const featureList =
        orderDetail?.plan?.features?.startsWith('[') &&
        orderDetail?.plan?.features?.endsWith(']')
            ? orderDetail?.plan?.features
                  ?.slice(1, -1)
                  ?.replace(/['"]/g, '')
                  ?.replace(/\\\//g, '/')
                  ?.split(',')
                  ?.map(feature => feature?.trim())
            : orderDetail?.plan?.features
                  ?.split(',')
                  ?.map(feature => feature?.trim());

    return (
        <Box>
            <Box className="content-header profile-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        View Order
                    </Typography>
                </Box>
            </Box>
            {isDetailLoading ? (
                <Box className="h-70vh">
                    <Loader />
                </Box>
            ) : (
                <Box className="content-layout mt-22 form-field-wrapper">
                    <Grid container spacing={3}>
                        <Grid item lg={8} md={7} sm={12} xs={12}>
                            <Box className="card-wrapper p-16">
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                            variant="h3"
                                            component="h3"
                                            className="card-title"
                                        >
                                            Order Details
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Full Name
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {orderDetail?.customer
                                                    ?.full_name || '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Phone Number
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {orderDetail?.customer
                                                    ?.phone_number || '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Email
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {orderDetail?.customer?.email ||
                                                    '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Stripe Subscription Id
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {orderDetail?.customer
                                                    ?.stripe_subscription_id ||
                                                    '-'}{' '}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Status
                                            </Typography>
                                            <Box
                                                className={`status ${
                                                    orderDetail?.status ===
                                                    'Cancelled'
                                                        ? 'red'
                                                        : orderDetail?.status ===
                                                          'Completed'
                                                        ? 'green'
                                                        : 'yellow'
                                                }`}
                                            >
                                                {orderDetail?.status}
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            <Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Created At
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {orderDetail?.created_at &&
                                                    getFormattedDateTime(
                                                        orderDetail?.created_at
                                                    )}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box className="field-wrapper">
                                            {/*<Typography
                                                variant="body1"
                                                component="label"
                                            >
                                                Updated At
                                            </Typography>
                                            <Typography
                                                className="input-ui"
                                                variant="body1"
                                                component="div"
                                            >
                                                {orderDetail?.updated_at &&
                                                    getFormattedDateTime(
                                                        orderDetail?.updated_at
                                                    )}
                                            </Typography>*/}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={12} xs={12}>
                            <Box className="card-wrapper h-100">
                                <Stack className="p-16">
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Plan Details
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Title
                                        </Typography>
                                        <Typography
                                            className="input-ui"
                                            variant="body1"
                                            component="div"
                                        >
                                            {(orderDetail?.plan?.title &&
                                                orderDetail?.plan?.title
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    orderDetail?.plan?.title.slice(
                                                        1
                                                    )) ||
                                                '-'}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Price
                                        </Typography>
                                        <Typography
                                            className="input-ui"
                                            variant="body1"
                                            component="div"
                                        >
                                            {`$` + orderDetail?.plan?.price ||
                                                '-'}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Type
                                        </Typography>
                                        <Typography
                                            className="input-ui"
                                            variant="body1"
                                            component="div"
                                        >
                                            {(orderDetail?.plan?.type &&
                                                orderDetail?.plan?.type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    orderDetail?.plan?.type.slice(
                                                        1
                                                    )) ||
                                                '-'}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Payment Url
                                        </Typography>
                                        <Typography
                                            className="input-ui"
                                            variant="body1"
                                            component="div"
                                        >
                                            {orderDetail?.plan?.payment_url ||
                                                '-'}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack className="p-16" spacing={3}>
                                    <Box className="field-wrapper">
                                        <Typography
                                            variant="body1"
                                            component="label"
                                        >
                                            Plan Features
                                        </Typography>
                                        <Typography
                                            className="input-ui"
                                            variant="body1"
                                            component="div"
                                        >
                                            {featureList?.map(
                                                (feature, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={feature}
                                                        sx={{
                                                            mb: '4px',
                                                            marginRight: '3px',
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="footer-wrapper">
                        <Box className="btn-wrapper">
                            {orderDetail?.status !== 'Cancelled' &&
                                user?.role === 'user' && (
                                    <Button
                                        className="btn red-btn"
                                        sx={{ width: 'auto!important' }}
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        Cancel Subscription
                                    </Button>
                                )}

                            <Button
                                className="btn secondary-btn"
                                onClick={() => navigate('/orders')}
                            >
                                Back
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
            <CancelSubscription open={open} onClose={handleClose} />
        </Box>
    );
};

export default ViewOrder;
