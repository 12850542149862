import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import userImage from '../../../src/assets/images/userImage.png';
import logoutIcon from '../../../src/assets/images/icons/logout.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { toast } from 'react-toastify';
import { getUserDetail } from '../../utils/apiConstants';

const Header = ({ toggleDrawerWidth, isDrawerOpen }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({})

    const getUserDetails = async() =>{
        try {
            const response = await getUserDetail();
            if (response?.status == 200) {
                setUser(response?.data?.data)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.response?.data?.error);
        }

    }
   

    const logout = () =>{
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        toast.success('Logged out Successfully!!')
        window.location.href = `${process.env.REACT_APP_FRONTEND_SITE_URL}/logout`;
    }

    useEffect(() => {
        const user = localStorage.getItem('user')
        if(!user){
            getUserDetails();
        }
        const parsedUser = user ? JSON.parse(user) : null;
        setUser(parsedUser)
    }, [])
    

    return (
        <>
            <AppBar position="fixed" className="navbar">
                <Toolbar>
                    {!isDrawerOpen && (
                        <Box
                            variant="div"
                            component="div"
                            className="navbar-left"
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => toggleDrawerWidth()}
                                className="toggle-btn"
                            >
                                <KeyboardArrowRightIcon className="open-menu" />
                            </IconButton>
                        </Box>
                    )}
                    <Box variant="div" component="div" className="navbar-right">
                        <Box
                            variant="div"
                            component="div"
                            className="user-info"
                        >
                            <img src={logoutIcon} alt="logout" onClick={() => logout()} />

                            <Typography className="divider-span"></Typography>

                            <Stack
                                direction={'row'}
                                gap={'10px'}
                                alignItems={'center'}
                                onClick={() => navigate('/my-account')}
                            >
                                <Typography className="username">
                                    Hi,{' '}{user?.displayname || user?.username || ''}
                                </Typography>
                                <Avatar
                                    src={userImage}
                                    className="user-profile"
                                ></Avatar>
                            </Stack>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
