import React, { useState, useEffect } from 'react';
import { Box, createTheme, useMediaQuery } from '@mui/material';
import Header from './header';
import SideMenu from './sideMenu';

const Layout = ({ children, open }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const theme = createTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (isSmallScreen) {
            setIsDrawerOpen(false);
        } else {
            if(!isDrawerOpen){
                setIsDrawerOpen(true);
            }
        }
    }, [isSmallScreen]);

    const toggleDrawerWidth = () => {
        setIsDrawerOpen(prev => !prev);
    };

    return (
        <Box open={open}>
            <Header toggleDrawerWidth={toggleDrawerWidth} isDrawerOpen={isDrawerOpen} />
            <SideMenu toggleDrawerWidth={toggleDrawerWidth} isDrawerOpen={isDrawerOpen} isSmallScreen={isSmallScreen}/>
            <main className="main-content">{children}</main>
        </Box>
    );
};

export default Layout;
