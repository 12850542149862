import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import createTheme from '@mui/material/styles/createTheme';
import SearchIcon from '@mui/icons-material/Search';
import Select from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteTrash from '../../assets/images/icons/delete.svg';
import { toast } from 'react-toastify';
import { deleteState, editState, getStates } from '../../utils/apiConstants';
import { handleSelectChange, pagintaionPageCount } from '../../utils/constants';
import Pagination from '../../components/common/pagination';
import Loader from '../../components/common/loader';
import { useDebounce } from '../../utils/useDebounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import UploadModal from '../../components/common/UploadModal';
import { CircularProgress, Fade, Modal } from '@mui/material';
import { ArrowDownward, ArrowUpward, SwapVert } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const theme = createTheme();

const States = () => {
    const [loader, setLoader] = useState(true);
    const [stateLoader, setStateLoader] = useState(false);
    const [states, setStates] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showPerPage, setShowPerPage] = useState(10);
    const [selectedPage, setSelectedPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [currentRow, setCurrentRow] = useState(null);
    const [currentImageType, setCurrentImageType] = useState('');
    const debouncedSearchTerm = useDebounce(searchValue, 500);
    const [openRow, setOpenRow] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortBy, setSortBy] = useState(null);
    const [fileData, setFileData] = useState([]); // New state to store file data
    const [selectedId, setSelectedId] = useState([]);
    const [headerChecked, setHeaderChecked] = useState(false);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const trashModalOpen = () => setOpen(true);
    const trashModalClose = () => setOpen(false);

    const handleHeaderCheckboxChange = () => {
        if (headerChecked) {
            setSelectedId([]);
        } else {
            const allId = states?.map(item => item?.id);
            setSelectedId(allId);
        }
        setHeaderChecked(!headerChecked);
    };

    const handleRowCheckboxChange = id => {
        if (selectedId.includes(id)) {
            setSelectedId(selectedId.filter(item => item !== id));
        } else {
            setSelectedId([...selectedId, id]);
        }
    };

    const handleImageUrlChange = (e, row, imageType) => {
        const newImageUrl = e.target.value;
        const updatedRow = { ...row, [imageType]: newImageUrl };
        const updatedImageData = states?.map(item =>
            item.slug === row.slug ? updatedRow : item
        );
        setStates(updatedImageData);
        if (row?.slug) {
            setFileData(prevFileData => [
                ...prevFileData,
                { slug: row.slug, newImageUrl, imageType },
            ]);
        }
    };

    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (row, imageType) => {
        setCurrentRow(row);
        setCurrentImageType(imageType);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleFileDrop = (files, row, imageType) => {
        handleFileChange(files?.[0], row, imageType);
        setModalOpen(false);
    };

    const getStateList = async (newSortOrder = sortOrder, column = sortBy) => {
        setLoader(true);
        try {
            const response = await getStates(
                selectedPage > 0 ? selectedPage : 1,
                showPerPage,
                searchValue,
                sortBy || column
                    ? (newSortOrder || sortOrder) === 'asc'
                        ? column || sortBy
                        : `-` + column || sortBy
                    : sortBy
            );
            if (response?.status == 200) {
                setStates(response?.data?.results?.data);
                setTotalCount(response?.data?.count || 0);
                setTotalPage(
                    Math.ceil(response?.data?.count / showPerPage) || 0
                );
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.detail ||
                    error?.response?.data?.message ||
                    error.message
            );
        }
        setLoader(false);
    };

    const fetchData = pageSize => {
        setSelectedPage(1);
        setShowPerPage(pageSize);
    };

    useEffect(() => {
        getStateList();
    }, [debouncedSearchTerm, showPerPage, selectedPage]);

    const handleRowClick = index => {
        setOpenRow(prevOpenRow => (prevOpenRow === index ? null : index));
    };

    const handleFileChange = (e, row, imageType, url) => {
        const file = e;
        if (file) {
            const updatedImageData = states?.map(item =>
                item.slug === row.slug
                    ? { ...item, [imageType]: URL.createObjectURL(file) }
                    : item
            );
            setStates(updatedImageData);
            setFileData(prevFileData => [
                ...prevFileData,
                { slug: row.slug, file, imageType },
            ]);
        }
    };

    const deleteStateById = async () => {
        setLoader(true);
        try {
            if (selectedId) {
                const responses = await deleteState(selectedId.join(','));

                if (responses?.data?.status == 200) {
                    setSelectedPage(1);
                    toast.success(responses?.data?.message);
                    setOpen(false);
                    setHeaderChecked(!headerChecked);
                    setSelectedId([]);
                }
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    error.message
            );
        }
        setLoader(false);
    };

    const handleSave = async () => {
        setStateLoader(true);
        const uploadPromises = fileData.map(({ slug, file, imageType }) => {
            const formData = new FormData();
            formData.append(imageType, file || '');
            return editState(slug, formData);
        });

        try {
            const responses = await Promise.all(uploadPromises);
            if (responses?.[0]?.data?.status == 200) {
                toast.success(responses?.[0]?.data?.message);
            }
            await getStateList();
        } catch (error) {
            toast.error(
                error?.response?.data?.message ||
                    error?.response?.data?.detail ||
                    error.message
            );
        } finally {
            setStateLoader(false);
        }
    };

    const handleSort = column => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        getStateList(newSortOrder, column);
    };

    const getSortIcon = column => {
        if (sortOrder === 'asc' && sortBy === column) {
            return <ArrowUpward size={20} />;
        } else if (sortOrder === 'desc' && sortBy === column) {
            return <ArrowDownward size={20} />;
        } else {
            return <SwapVert size={20} />;
        }
    };

    return (
        <>
            <Box className="content-header companies-module-wrapper">
                <Box className="content-header-left">
                    <Typography variant="h2" component="h2">
                        States
                    </Typography>
                </Box>
                <Box className="content-header-right">
                    <Box className="item-badge">{totalCount} items</Box>
                    <Box variant="div" component="div" className="search-bar">
                        <SearchIcon />
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Search State"
                            onChange={e => {
                                setSearchValue(e.target.value);
                                setSelectedPage(1);
                            }}
                        />
                    </Box>
                </Box>
            </Box>
            <Box className="content-layout">
                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box className="card-wrapper border-1-5">
                            <TableContainer className="companies-table-ui">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead className="bg-primary-10">
                                        <TableRow className="hide-thead">
                                            <TableCell className="table-checkbox-width">
                                                <Checkbox
                                                    className="checkbox-ui"
                                                    checked={headerChecked}
                                                    onChange={
                                                        handleHeaderCheckboxChange
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell
                                                onClick={() => {
                                                    setSortBy('name');
                                                    handleSort('name');
                                                }}
                                            >
                                                Name {getSortIcon('name')}
                                            </TableCell>
                                            <TableCell>
                                                Desktop Image{' '}
                                            </TableCell>
                                            <TableCell>Mobile Image </TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loader ? (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    align={'center'}
                                                >
                                                    <Loader />
                                                </TableCell>
                                            </TableRow>
                                        ) : states?.length > 0 ? (
                                            states?.map((row, index) => (
                                                <React.Fragment key={index}>
                                                    <TableRow
                                                        onClick={e => {
                                                            if (
                                                                isSmallScreen &&
                                                                e.target
                                                                    .type !==
                                                                    'checkbox'
                                                            ) {
                                                                handleRowClick(
                                                                    index
                                                                );
                                                            }
                                                        }}
                                                        className={
                                                            openRow === index
                                                                ? 'open'
                                                                : ''
                                                        }
                                                    >
                                                        <TableCell className="table-checkbox-width">
                                                            <Checkbox
                                                                className="checkbox-ui"
                                                                checked={selectedId.includes(
                                                                    row?.id
                                                                )}
                                                                onChange={() =>
                                                                    handleRowCheckboxChange(
                                                                        row?.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box className="show-text-arrow-content">
                                                                {row?.name}
                                                                {openRow ===
                                                                index ? (
                                                                    <KeyboardArrowUpIcon />
                                                                ) : (
                                                                    <KeyboardArrowDownIcon />
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Box>
                                                                <Box className="input-group flex">
                                                                    <TextField
                                                                        variant="filled"
                                                                        className="form-control"
                                                                        id={`image_${row.slug}`}
                                                                        name={`image[${row.slug}][m]`}
                                                                        type="text"
                                                                        value={
                                                                            row?.image ||
                                                                            ''
                                                                        }
                                                                        onChange={e =>
                                                                            handleImageUrlChange(
                                                                                e,
                                                                                row,
                                                                                'image'
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        // variant="outlined"
                                                                        // className="button-browse-image"
                                                                        onClick={() => {
                                                                            handleOpenModal(
                                                                                row,
                                                                                'image'
                                                                            );
                                                                        }}
                                                                    >
                                                                        Browse
                                                                    </Button>
                                                                    <UploadModal
                                                                        open={
                                                                            modalOpen
                                                                        }
                                                                        row={
                                                                            currentRow
                                                                        }
                                                                        imageType={
                                                                            currentImageType
                                                                        }
                                                                        onClose={
                                                                            handleCloseModal
                                                                        }
                                                                        onDrop={
                                                                            handleFileDrop
                                                                        }
                                                                    />
                                                                </Box>
                                                                {row.image && (
                                                                    <img
                                                                        src={
                                                                            row.image
                                                                        }
                                                                        className='state-image'
                                                                        alt={
                                                                            row.name
                                                                        }
                                                                    />
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Box>
                                                                <Box className="input-group flex">
                                                                    <TextField
                                                                        variant="filled"
                                                                        className="form-control"
                                                                        id={`image_mobile_${row.slug}`}
                                                                        name={`image[${row.slug}][m]`}
                                                                        value={
                                                                            row?.image_mobile ||
                                                                            ''
                                                                        }
                                                                        onChange={e =>
                                                                            handleImageUrlChange(
                                                                                e,
                                                                                row,
                                                                                'image_mobile'
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        // variant="outlined"
                                                                        // className="button-browse-image"
                                                                        onClick={() => {
                                                                            handleOpenModal(
                                                                                row,
                                                                                'image_mobile'
                                                                            );
                                                                        }}
                                                                    >
                                                                        Browse
                                                                    </Button>
                                                                    <UploadModal
                                                                        open={
                                                                            modalOpen
                                                                        }
                                                                        row={
                                                                            currentRow
                                                                        }
                                                                        imageType={
                                                                            currentImageType
                                                                        }
                                                                        onClose={
                                                                            handleCloseModal
                                                                        }
                                                                        onDrop={
                                                                            handleFileDrop
                                                                        }
                                                                    />
                                                                </Box>
                                                                {row?.image_mobile && (
                                                                    <img
                                                                        src={
                                                                            row?.image_mobile
                                                                        }
                                                                        className='state-image'
                                                                        alt={
                                                                            row.id
                                                                        }
                                                                    />
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="hide-td">
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                gap={3}
                                                                className="action-wrapper"
                                                            >
                                                                <Button
                                                                    className="btn delete-btn"
                                                                    onClick={() => {
                                                                        setSelectedId(
                                                                            [
                                                                                ...selectedId,
                                                                                row?.id,
                                                                            ]
                                                                        );
                                                                        trashModalOpen();
                                                                    }}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </Button>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    {openRow === index && (
                                                        <TableRow className="accordion-content">
                                                            <TableCell
                                                                colSpan={4}
                                                            >
                                                                <Box className="accordion-box">
                                                                    <Stack
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="MuiLink-root fw-600"
                                                                        >
                                                                            Name:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {
                                                                                    row?.name
                                                                                }
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Desktop
                                                                            Image:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                {row?.image && (
                                                                                    <img
                                                                                        src={
                                                                                            row?.image
                                                                                        }
                                                                                        className='state-image'
                                                                                        alt={
                                                                                            row.name
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                <Box className="input-group flex">
                                                                                    <TextField
                                                                                        variant="filled"
                                                                                        className="form-control"
                                                                                        id={`image_${row.slug}`}
                                                                                        name={`image[${row.slug}][m]`}
                                                                                        type="text"
                                                                                        value={
                                                                                            row?.image ||
                                                                                            ''
                                                                                        }
                                                                                        onChange={e =>
                                                                                            handleImageUrlChange(
                                                                                                e,
                                                                                                row,
                                                                                                'image'
                                                                                            )
                                                                                        }
                                                                                    />

                                                                                    <Button
                                                                                        // variant="outlined"
                                                                                        // className="button-browse-image"
                                                                                        onClick={() => {
                                                                                            handleOpenModal(
                                                                                                row,
                                                                                                'image'
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Browse
                                                                                    </Button>
                                                                                    <UploadModal
                                                                                        open={
                                                                                            modalOpen
                                                                                        }
                                                                                        row={
                                                                                            currentRow
                                                                                        }
                                                                                        imageType={
                                                                                            currentImageType
                                                                                        }
                                                                                        onClose={
                                                                                            handleCloseModal
                                                                                        }
                                                                                        onDrop={
                                                                                            handleFileDrop
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body1"
                                                                            component="p"
                                                                            className="fw-600"
                                                                        >
                                                                            Mobile
                                                                            Image:
                                                                            <Typography
                                                                                variant="body2"
                                                                                component="p"
                                                                            >
                                                                                <Box>
                                                                                    {row?.image_mobile && (
                                                                                        <img
                                                                                            src={
                                                                                                row?.image_mobile
                                                                                            }
                                                                                            className='state-image'
                                                                                            alt={
                                                                                                row.id
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    <Box className="input-group flex">
                                                                                        <TextField
                                                                                            variant="filled"
                                                                                            className="form-control"
                                                                                            id={`image_mobile_${row.slug}`}
                                                                                            name={`image[${row.slug}][m]`}
                                                                                            value={
                                                                                                row?.image_mobile ||
                                                                                                ''
                                                                                            }
                                                                                            onChange={e =>
                                                                                                handleImageUrlChange(
                                                                                                    e,
                                                                                                    row,
                                                                                                    'image_mobile'
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <Button
                                                                                            // variant="outlined"
                                                                                            // className="button-browse-image"
                                                                                            onClick={() => {
                                                                                                handleOpenModal(
                                                                                                    row,
                                                                                                    'image_mobile'
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Browse
                                                                                        </Button>
                                                                                        <UploadModal
                                                                                            open={
                                                                                                modalOpen
                                                                                            }
                                                                                            row={
                                                                                                currentRow
                                                                                            }
                                                                                            imageType={
                                                                                                currentImageType
                                                                                            }
                                                                                            onClose={
                                                                                                handleCloseModal
                                                                                            }
                                                                                            onDrop={
                                                                                                handleFileDrop
                                                                                            }
                                                                                        />
                                                                                    </Box>
                                                                                </Box>
                                                                            </Typography>
                                                                        </Typography>
                                                                        <Stack
                                                                            direction={
                                                                                'row'
                                                                            }
                                                                            gap={
                                                                                3
                                                                            }
                                                                            className="action-wrapper"
                                                                        >
                                                                            <Button
                                                                                className="btn delete-btn"
                                                                                onClick={() => {
                                                                                    setSelectedId(
                                                                                        [
                                                                                            ...selectedId,
                                                                                            row?.id,
                                                                                        ]
                                                                                    );
                                                                                    trashModalOpen();
                                                                                }}
                                                                            >
                                                                                <DeleteForeverIcon />
                                                                            </Button>
                                                                        </Stack>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={5}
                                                    className="table-checkbox-width"
                                                    align="center"
                                                >
                                                    No Record Found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        {totalCount > 0 && (
                            <Grid container>
                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                    <Box
                                        className="save-button-wrapper"
                                        sx={{ marginTop: '8px' }}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={handleSave}
                                            className="btn primary-btn w-100"
                                            disabled={stateLoader}
                                            endIcon={
                                                stateLoader && (
                                                    <CircularProgress
                                                        sx={{
                                                            color: 'var(--white-color)',
                                                        }}
                                                        size={16}
                                                    />
                                                )
                                            }
                                        >
                                            Save States
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                        <Box className="pagination-wrapper">
                            <Box className="item-page-select">
                                <Typography variant="body1" component="span">
                                    Items per page
                                </Typography>
                                <Select
                                    defaultValue={pagintaionPageCount?.find(
                                        option => option.value === showPerPage
                                    )}
                                    menuPlacement="auto"
                                    className={'select-ui'}
                                    classNamePrefix="select"
                                    onChange={selectedOption =>
                                        handleSelectChange(
                                            selectedOption,
                                            setShowPerPage,
                                            fetchData
                                        )
                                    }
                                    options={pagintaionPageCount}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </Box>
                            <Pagination
                                selectedPage={selectedPage}
                                totalPage={totalPage}
                                setSelectedPage={setSelectedPage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Modal
                open={open}
                onClose={trashModalClose}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box className="modal-wrapper modal-ui">
                        <Box className="modal-body">
                            <Box className="confirmation-message-wrapper">
                                <Box className="image-wrapper">
                                    <img src={DeleteTrash} />
                                </Box>
                                <Box className="confirm-content-wrapper">
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        className="confirm-title"
                                    >
                                        Are you sure want to delete this state?
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="label"
                                        className="confirm-para"
                                    >
                                        This action is permanent and cannot be
                                        undone.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="modal-footer-wrapper">
                                <Button
                                    className="btn red-btn"
                                    onClick={() => deleteStateById()}
                                >
                                    Delete
                                </Button>
                                <Button
                                    className="btn secondary-btn"
                                    onClick={trashModalClose}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default States;
