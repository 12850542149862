import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addUser, getUserById, updateUser } from '../../utils/apiConstants';
import { toast } from 'react-toastify';
import Loader from '../../components/common/loader';
import { CircularProgress } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const RoleOption = [
    { value: 'user', label: 'User' },
    { value: 'editor', label: 'Editor' },
    { value: 'admin', label: 'Admin' },
    { value: 'author', label: 'Author' },
];

const validationSchema = Yup.object().shape({
    displayname: Yup.string()
        .required('Display Name is required')
        .matches(
            /^(?![-\s]*$).+/,
            'Display Name cannot be empty or only contain spaces and hyphens'
        ),
    username: Yup.string()
        .required('Username is required')
        .matches(
            /^(?![-\s]*$).+/,
            'Username cannot be empty or only contain spaces and hyphens'
        ),
    email: Yup.string().required('Email is required').email('Invalid Email'),
    role: Yup.string().required('Role is required'),
});

const IOSSwitch = styled(props => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
))(({ theme }) => ({
    width: 60,
    height: 30,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        top: '3px',
        boxShadow: 'box-shadow: 0px 0px 8px 0px #0000001A',
        transform: 'translateX(3px)',
        color: '#A5A5A5',
        '&.Mui-checked': {
            transform: 'translateX(34px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#D9D9D9' : '#0DA9F4',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
    },
    '& .MuiSwitch-track': {
        borderRadius: 100 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const AddEditUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [userDetail, setUserDetail] = useState([]);

    const initialValues = {
        displayname: userDetail?.displayname ? userDetail?.displayname : '',
        username: userDetail?.username ? userDetail?.username : '',
        email: userDetail?.email ? userDetail?.email : '',
        role: userDetail?.role ? userDetail?.role : RoleOption[0]?.value,
        is_active: userDetail?.is_active ? userDetail?.is_active : false,
    };

    const getUserDetailsById = async () => {
        setIsDetailLoading(true);
        try {
            const response = await getUserById(id);
            if (response?.status === 200) {
                setUserDetail(response?.data?.data);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
        setIsDetailLoading(false);
    };

    const saveUserDetail = async values => {
        setLoader(true);
        try {
            let responses;
            if (id) {
                responses = await updateUser(id, values);
            } else {
                responses = await addUser(values);
            }
            if (
                responses?.data?.status == 201 ||
                responses?.data?.status == 200
            ) {
                toast.success(responses?.data?.message);
            }
            navigate('/users');
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
        setLoader(false);
    };

    useEffect(() => {
        if (id) {
            getUserDetailsById();
        }
    }, [id]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                saveUserDetail(values);
                resetForm();
                setSubmitting(false);
            }}
            enableReinitialize
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <Box className="content-header profile-module-wrapper">
                        <Box className="content-header-left">
                            <Typography variant="h2" component="h2">
                                {id ? 'Edit' : 'Add'} User
                            </Typography>
                        </Box>
                    </Box>
                    {isDetailLoading ? (
                        <Box className="h-70vh">
                            <Loader />
                        </Box>
                    ) : (
                        <Box className="content-layout mt-22 form-field-wrapper">
                            <Grid container spacing={3}>
                                <Grid item lg={8} md={7} sm={12} xs={12}>
                                    <Box className="card-wrapper p-16">
                                        <Grid container spacing={3}>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Typography
                                                    variant="h3"
                                                    component="h3"
                                                    className="card-title"
                                                >
                                                    User Details
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Display Name
                                                    </Typography>
                                                    <Field
                                                        name="displayname"
                                                        as={TextField}
                                                        className="input-ui"
                                                        placeholder="Enter Display Name"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage
                                                        name="displayname"
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Username
                                                    </Typography>
                                                    <Field
                                                        name="username"
                                                        as={TextField}
                                                        className="input-ui"
                                                        placeholder="Enter Username"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage
                                                        name="username"
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Box className="field-wrapper">
                                                    <Typography
                                                        variant="body1"
                                                        component="label"
                                                    >
                                                        Email
                                                    </Typography>
                                                    <Field
                                                        name="email"
                                                        as={TextField}
                                                        className="input-ui"
                                                        placeholder="Enter Email"
                                                        fullWidth
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="error-message"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item lg={4} md={5} sm={12} xs={12}>
                                    <Box className="card-wrapper h-100">
                                        <Stack className="p-16" spacing={3}>
                                            <Box className="field-wrapper">
                                                <Typography
                                                    variant="body1"
                                                    component="label"
                                                >
                                                    Role
                                                </Typography>
                                                <Select
                                                    menuPlacement="auto"
                                                    value={RoleOption?.filter(
                                                        option =>
                                                            values?.role?.includes(
                                                                option?.value
                                                            )
                                                    )}
                                                    className={'select-ui'}
                                                    classNamePrefix="select"
                                                    options={RoleOption}
                                                    placeholder="Select Role"
                                                    onChange={option =>
                                                        setFieldValue(
                                                            'role',
                                                            option?.value
                                                        )
                                                    }
                                                />
                                                <ErrorMessage
                                                    name="role"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Box>
                                            <Box className="field-wrapper">
                                                <Typography
                                                    variant="body1"
                                                    component="label"
                                                >
                                                    Status
                                                </Typography>
                                                <FormControlLabel
                                                    checked={values.is_active}
                                                    className='p-16'
                                                    onChange={(e) =>{ setFieldValue('is_active', e.target.checked)}}
                                                    control={
                                                        <IOSSwitch
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="footer-wrapper">
                                <Box className="btn-wrapper">
                                    <Button
                                        className="btn primary-btn"
                                        type="submit"
                                        disabled={loader}
                                        endIcon={
                                            loader && (
                                                <CircularProgress
                                                    sx={{
                                                        color: 'var(--white-color)',
                                                    }}
                                                    size={16}
                                                />
                                            )
                                        }
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="btn secondary-btn"
                                        onClick={() => navigate('/users')}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default AddEditUser;
