
export const LocalUtils = {
    /**
     *
     * @param {String} key - localStorage key | Declaring key as constants is recommended
     * @returns Object
     */
    getItem: (key) => {
        return JSON.parse(localStorage.getItem(key))
    },

    /**
     *
     * @param {String} key - - localStorage key | Declaring key as constants is recommended
     * @param {*} key
     */
    setItem: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
    },

    /**
     *
     * @param {}
     * @return {String|null} TOKEN
     */
    getToken: () => {
        const token = localStorage.getItem('token');
        // if (!user) return null
        
        return token
    }
}
